import { observer } from "mobx-react-lite";
import { Text, Card } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { ViewGlobalMapping } from "../../globalMapping/ViewGlobalMapping";
import { NewGlobalMapping } from "../../globalMapping/NewGlobalMapping";
import { NewAutoCompleteConfig } from "../../autoComplete/NewAutoCompleteConfig";
import { ViewAutoCompleteConfig } from "../../autoComplete/ViewAutoCompleteConfig";
import { NewMinimumMatchingRule } from "./MinimumMatchingCriteria/NewMinimumMatchingRule";
import { MatchingExclusionCriteria } from "./MinimumMatchingCriteria/MatchingExclusionCriteria";
import { MODALS, REC_TYPE_CONFIG_VIEWS } from "../../../utils/enums";
import { CarryForwardSetup } from "./CarryForwardSetup";

const RecTypeConfig = observer(({ recTypeId, currentView }) => {
  const { globalMappingStore, manualMatchingCriteriaStore, autoCompleteStore, carryForwardStore } = useStore();
  const globalMappings = globalMappingStore.getGlobalMappings();
  const autoCompleteSettings = autoCompleteStore.getAutoCompleteRules();
  const autoCompleteSettingsByRecType = autoCompleteSettings.filter((it) => it.recTypeId === recTypeId);
  const matchingCriteria = manualMatchingCriteriaStore.getManualMatchingCriteria();
  const carryForwardRules = carryForwardStore.getCarryForwardRules();

  return (
    <>
      {REC_TYPE_CONFIG_VIEWS.GLOBAL_MAPPING_VIEW === currentView ? (
        <ConfigSection
          title="Global Mappings"
          description="Global mappings allow non-identical mapped fields to be saved as a match for future reconciliations."
        >
          {globalMappings.length ? <ViewGlobalMapping /> : <NewGlobalMapping />}
        </ConfigSection>
      ) : REC_TYPE_CONFIG_VIEWS.AUTO_COMPLETE_VIEW === currentView ? (
        <ConfigSection
          title="Auto complete"
          description="Auto complete allows reconciliations to be automatically completed when specific conditions are met. These conditions are defined through auto complete rules. Many rules can be defined here but a fund can have one auto complete rule enabled at any given time."
        >
          {autoCompleteSettingsByRecType.length ? <ViewAutoCompleteConfig autoCompleteConfig={autoCompleteSettingsByRecType[0]} /> : <NewAutoCompleteConfig />}
        </ConfigSection>
      ) : REC_TYPE_CONFIG_VIEWS.MANUAL_MATCHING_VIEW === currentView ? (
        <ConfigSection title="Manual matching" description="Configure minimum criteria rules for manual matching">
          {matchingCriteria.length ? <MatchingExclusionCriteria minimumCriteriaList={matchingCriteria} /> : <NewMinimumMatchingRule />}
        </ConfigSection>
      ) : REC_TYPE_CONFIG_VIEWS.CARRY_FORWARD_VIEW === currentView ? (
        <>
          <ConfigSection
            title="Carry forward manual actions"
            description="Compare unprocessed row data in the current reconciliation with processed row data in the previous reconciliation. If the same rows existed then carry forward/repeat any manual actions taken. This runs after automated matching rules."
          >
            <CarryForwardSetup
              title="Carry forward manual actions"
              description="Compare unprocessed row data in the current reconciliation with processed row data in the previous reconciliation. If the same rows existed then carry forward/repeat any manual actions taken. This runs after automated matching rules."
              modalId={MODALS.CARRY_FORWARD_MANUAL_ACTIONS}
              manualActions={true}
              recTypeId={recTypeId}
              carryForwardConfig={carryForwardRules && carryForwardRules.manual ? carryForwardRules.manual : null}
            />
          </ConfigSection>
          <ConfigSection
            title="Carry forward tags, comments and aging"
            description="Compare processed row data in the current reconciliation with processed row data in the previous reconciliation. If the category and row data is the same then carry forward any tags, comments or aging applied. This runs after automated matching rules."
          >
            <CarryForwardSetup
              title="Carry forward tags, comments and aging"
              description="Compare processed row data in the current reconciliation with processed row data in the previous reconciliation. If the category and row data is the same then carry forward any tags, comments or aging applied. This runs after automated matching rules."
              modalId={MODALS.CARRY_FORWARD_TAGS_COMMENTS}
              recTypeId={recTypeId}
              carryForwardConfig={carryForwardRules && carryForwardRules.auto ? carryForwardRules.auto : null}
            />
          </ConfigSection>
        </>
      ) : (
        ""
      )}
      <div className="mt-32 mb-32"></div>
    </>
  );
});

const ConfigSection = ({ title, description, children }) => {
  return (
    <>
      <div className="pt-32" style={{ height: "" }}>
        <Text size="lg" element="div">
          {title}
        </Text>
        <Text size="sm" weight="regular" variant="tertiary" element="div">
          {description}
        </Text>
      </div>
      <Card spacers={["mt-16", "pt-24", "pl-32", "pr-32", "pb-24"]}>{children}</Card>
    </>
  );
};

export { RecTypeConfig };
