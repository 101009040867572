import { ToolTip } from "@fundrecs/ui-library";
import "./toolbarButtons.css";

const ToolBarButton = ({ icon, onClick, hoverText }) => {
  return (
    <ToolTip text={hoverText} direction={"top"} size="medium">
      <div
        className="toolbar-button"
        onClick={(e) => {
          onClick(e);
        }}
      >
        {icon}
      </div>
    </ToolTip>
  );
};

export { ToolBarButton };
