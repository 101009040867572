import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { modalInstance, Text, IconCloseRemove } from "@fundrecs/ui-library";
import { ToolBarButton } from "./ToolBarButton";
import { ReactComponent as TagsIcon } from "../../../icons/tag.svg";
import { ReactComponent as CommentsIcon } from "../../../icons/comment.svg";
import { useStore } from "../../../store/Store";
import { AddNotePanel } from "../sidePanels/AddNotePanel";
import { AUTHORITIES, MODALS } from "../../../utils/enums";
import { AuthWrapper } from "../../AuthorizationWrapper";
import { AnimatedPanel } from "../../reusable/AnimatedPanel/AnimatedPanel";
import { AddCommentPanel } from "../sidePanels/AddCommentPanel";

const ProcessedRowsToolbar = ({ processedTableRef, updateTable }) => {
  const { uiStore, rolesStore } = useStore();
  const [searchParams] = useSearchParams();
  const teamId = Number(searchParams.get("teamId"));
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowGroups, setSelectedRowGroups] = useState([]);
  const TAGS = "Tags";
  const COMMENT = "Comment";
  const [selectedTab, setSelectedTab] = useState(COMMENT);
  const [commentPanelOpen, setCommentPanelOpen] = useState(false);

  const openTagsPanel = () => {
    if (processedTableRef && processedTableRef.current && processedTableRef.current.api && processedTableRef.current.api.getSelectedRows().length === 1) {
      setSelectedRow(processedTableRef.current.api.getSelectedRows()[0]);
      modalInstance(MODALS.ADD_NOTE).show();
      /*
      if (selectedTab === TAGS) {
        setCommentPanelOpen(!commentPanelOpen);
      }
      setSelectedTab(TAGS);
      */
    } else {
      uiStore.addNotification("error", "Please select 1 row");
    }
  };

  const openCommentPanel = () => {
    if (processedTableRef && processedTableRef.current && processedTableRef.current.api && processedTableRef.current.api.getSelectedRows().length) {
      setSelectedRowGroups(processedTableRef.current.api.getSelectedRows());
      if (selectedTab === COMMENT) {
        setCommentPanelOpen(!commentPanelOpen);
      }
      setSelectedTab(COMMENT);
    } else {
      uiStore.addNotification("error", "Please select at least 1 row");
    }
  };

  const tabs =
    processedTableRef && processedTableRef.current && processedTableRef.current.api && processedTableRef.current.api.getSelectedRows().length === 1
      ? [COMMENT] //[TAGS, COMMENT]
      : [COMMENT];

  const Tab = ({ text, selected }) => {
    return (
      <div
        className="ml-8 mr-8 pt-16 pb-16"
        style={{ cursor: "pointer", borderBottom: selected ? "3px solid #F54828" : "" }}
        onClick={() => {
          setSelectedTab(text);
        }}
      >
        <Text size="sm" weight={selected ? "bold" : "regular"}>
          {text}
        </Text>
      </div>
    );
  };

  const updateTableAndClosePanel = () => {
    updateTable();
    setCommentPanelOpen(false);
  };

  return (
    <>
      <AuthWrapper teamId={teamId} oneRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_ADD_COMMENT])}>
        <ToolBarButton icon={<CommentsIcon />} onClick={openCommentPanel} />
      </AuthWrapper>
      <AuthWrapper teamId={teamId} oneRequired={rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_ADD_NOTE, AUTHORITIES.RECS_ROW_GROUP_REMOVE_NOTE])}>
        <ToolBarButton icon={<TagsIcon />} onClick={openTagsPanel} hoverText="Open tags" />
      </AuthWrapper>
      <AnimatedPanel open={commentPanelOpen}>
        <>
          <div style={{ borderBottom: "1px solid #E6E8EB" }} className="pl-16 pt-32 pr-16">
            <div className=" d-flex justify-content-between">
              <Text size="lg" element="div">{`${selectedRowGroups.length} items selected`}</Text>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCommentPanelOpen(false);
                }}
              >
                <IconCloseRemove className="btn-lg-svg" />
              </div>
            </div>
            <div className="d-flex mt-16">
              {tabs.map((it) => {
                return <Tab text={it} selected={it === selectedTab} />;
              })}
            </div>
          </div>
          <div className="pl-16 pr-16 pt-32">
            {selectedTab === TAGS ? (
              ""
            ) : selectedTab === COMMENT ? (
              <AddCommentPanel teamId={teamId} updateTable={updateTableAndClosePanel} rowGroups={selectedRowGroups} />
            ) : (
              ""
            )}
          </div>
        </>
      </AnimatedPanel>
      <AddNotePanel teamId={teamId} rowGroup={selectedRow} updateTable={updateTable} />
    </>
  );
};

export { ProcessedRowsToolbar };
