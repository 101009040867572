import "ag-grid-enterprise";
import { forwardRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import { Spinner } from "../layout/Spinner";
// TODO: create custom theme and replace here
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./ag-grid-fundrecs-styles.css";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-062158}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Fund_Recs}_is_granted_a_{Multiple_Applications}_Developer_License_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Charts_and_AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{16_July_2025}____[v3]_[0102]_MTc1MjYyMDQwMDAwMA==3304c8efa61b525af598a1b0c426a21f"
);

const STANDARD_HEIGHT = 40;

const Table = forwardRef(
  (
    {
      columnDefs,
      rowData,
      paginationSize = 500,
      height,
      width,
      rowSelection,
      updateSelected,
      fullWidthCellRenderer,
      colFlex = false,
      onDragStopped = undefined,
      rowClassRules = {},
      sizeToFit = false,
      autoSizeColumns = false,
      rowStyle = null,
      rowHeight = STANDARD_HEIGHT,
      headerHeight = STANDARD_HEIGHT,
      masterDetail = false,
      detailCellRendererParams = {},
      detailRowHeight = 250,
    },
    ref
  ) => {
    const sizeColumns = () => {
      if (sizeToFit) {
        ref.current.api.sizeColumnsToFit();
      } else if (autoSizeColumns) {
        ref.current.columnApi.autoSizeAllColumns(false);
      }
    };

    const gridOptions = {
      onDragStopped: onDragStopped,
      groupDisplayType: "multipleColumns",
    };

    const autoGroupColumnDef = {
      cellRendererParams: {
        suppressCount: true,
      },
    };

    const rowClassRulesDefined = useMemo(() => {
      return rowClassRules;
    }, []);

    const NoRowsOverlay = () => {
      return <span className="ag-overlay-loading-center">No rows found.</span>;
    };

    //If rowData is null, data is loading
    if (ref && ref.current && ref.current.api) {
      if (rowData === null) {
        ref.current.api.showLoadingOverlay();
      } else if (rowData && rowData.length === 0) {
        ref.current.api.showNoRowsOverlay();
      }
    }

    const style = { padding: "0px", minHeight: "100px", height: height ? height : "70vh", width: width ? width : "100% !important" };
    const defaultColDef = { sortable: true, filter: true, resizable: true, flex: colFlex ? 1 : 0 };
    return (
      <div style={style}>
        <AgGridReact
          className="muli-font-family ag-theme-alpine "
          autoGroupColumnDef={autoGroupColumnDef}
          gridOptions={gridOptions}
          ref={ref}
          rowData={rowData}
          rowDragManaged={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onRowSelected={updateSelected}
          rowSelection={rowSelection}
          rowMultiSelectWithClick={true}
          pagination={paginationSize > 0}
          paginationPageSize={paginationSize}
          loadingOverlayComponent={Spinner}
          noRowsOverlayComponent={NoRowsOverlay}
          suppressDragLeaveHidesColumns={true}
          enableCellTextSelection={true}
          rowClassRules={rowClassRulesDefined}
          onFirstDataRendered={sizeColumns}
          onModelUpdated={sizeColumns}
          rowStyle={rowStyle}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          masterDetail={masterDetail}
          detailCellRendererParams={detailCellRendererParams}
          keepDetailRows={true}
          detailRowHeight={detailRowHeight}
          isFullWidthRow={(params) => params.rowNode.data && params.rowNode.data.fullWidth}
          fullWidthCellRenderer={fullWidthCellRenderer}
        ></AgGridReact>
      </div>
    );
  }
);

export { Table };
