import { observer } from "mobx-react-lite";
import { Dropdown, DropdownButton, IconDownArrow, DropdownList, DropdownListItem, DropdownItem, ItemBody } from "@fundrecs/ui-library";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store";
import { useContext } from "react";
import { MatchingContext } from "./matchingReducer";

const DifferenceCalculator = observer(({ differencesRowData, show = false, updateDifferences }) => {
  const { tmoAggregatorStore, recTypeStore } = useStore();
  const aggregators = tmoAggregatorStore.getAggregators();
  const tmos = recTypeStore.getRecTypeTmos();
  const selectedAggregators = useContext(MatchingContext)["aggregators"];

  return show ? (
    <>
      <div style={{ background: "#1e5eff", borderRadius: "4px 4px 0px 0px" }} className="mt-8 pl-4 pt-4 pr-4 pb-4 d-flex justify-content-between">
        <div className="d-flex">
          <div className="text-sm text-bold" style={{ color: "white" }}>
            Differences
          </div>
          <div className="text-xs ml-16" style={{ color: "white", paddingTop: "2px" }}>
            Select rows to preview the calculated output. Max 20 rows
          </div>
        </div>
        <AggregatorSelectors tmos={tmos} selectedAggregators={selectedAggregators} aggregators={aggregators} updateDifferences={updateDifferences} />
      </div>
      <div className="pt-8 pl-8 pr-8 pb-8" style={{ borderRadius: "0px 0px 4px 4px", border: "1px solid #E6E7EB", background: "#fff" }}>
        {differencesRowData ? <Table paginationSize={0} columnDefs={differencesRowData.columns} rowData={differencesRowData.rows} height={"130px"} /> : ""}
      </div>
    </>
  ) : (
    ""
  );
});

const AggregatorSelectors = ({ tmos, selectedAggregators, aggregators, updateDifferences }) => {
  let show = false;
  tmos.forEach((tmo) => {
    if (!show) {
      show = aggregators.filter((it) => it.tmoId === tmo.id).length > 1;
    }
  });

  return show ? (
    <div className="d-flex">
      <div className="text-xs text-bold mr-16" style={{ color: "white" }}>
        Flattening ruleset
      </div>
      <div className="d-flex">
        {tmos.map((tmo, index) => {
          return (
            <>
              <Dropdown>
                <DropdownButton warning={false} disabled={false} size="sm">
                  {selectedAggregators && selectedAggregators[tmo.id] ? selectedAggregators[tmo.id]["name"] : "Please select an aggregator"}
                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                </DropdownButton>

                <DropdownList>
                  {aggregators
                    .filter((it) => it.tmoId === tmo.id)
                    .map((aggregator, index) => {
                      return (
                        <DropdownListItem
                          key={Math.random()}
                          onClick={() => {
                            updateDifferences({ ...selectedAggregators, [tmo.id]: aggregator });
                          }}
                        >
                          <DropdownItem active={false} index={index}>
                            <ItemBody>{aggregator.name}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                </DropdownList>
              </Dropdown>
              {index < tmos.length - 1 ? <span className="mr-8"></span> : ""}
            </>
          );
        })}
      </div>
    </div>
  ) : (
    ""
  );
};

export { DifferenceCalculator };
