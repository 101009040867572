import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { Text, R, Button, IconDownload, ToolTip, modalInstance, DatePicker } from "@fundrecs/ui-library";
import { AUTHORITIES, MODALS, STATUS } from "../../../utils/enums.js";
import { MainContainer, ManageLayout } from "../../layout/Layout.js";
import { PageTitleArea } from "../../layout/Layout.js";
import { Table } from "../../ag-grid/Ag-grid.js";
import { useStore } from "../../../store/Store.js";
import { convertToDate, getFirstDayOfMonth, stringifyDate, getDayString } from "../../../utils/dates.js";
import { PATH } from "../../../utils/urls.js";
import { AuthWrapper } from "../../AuthorizationWrapper.js";
import { ManualFileUploadModal } from "../../uploads/ManualFileUploadModal.js";
import { StatusBadge } from "../../reusable/StatusBadge.js";
import { ifNullUndefinedArray } from "../../../utils/utils.js";
import { AnimatedPanel } from "../../reusable/AnimatedPanel/AnimatedPanel.js";
import { DashboardCalendar } from "./DashboardCalendar.js";
import { ReactComponent as IconCalendar } from "../../../icons/_calendar-theme-quartz.svg";
import { ReactComponent as IconCalendarSelected } from "../../../icons/_calendar-theme-alpine.svg";
import { SetAsDayOneModal } from "./SetAsDayOneModal.js";
import { SkipToDateModal } from "./SkipToDateModal.js";
import { ReopenModal } from "./ReopenModal.js";

const RecTypeDash = observer(() => {
  const { clientStore, recStore, teamStore, recTypeStore, rolesStore, meStore } = useStore();
  const gridRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fundRows, setFundRows] = useState(null);
  const [fundRowsInitialised, setFundRowsInitialised] = useState(false);
  const [selectedDate, setSelectedDate] = useState(searchParams.get("date") ? convertToDate(searchParams.get("date")) : new Date());
  const [recTypeId, setRecTypeId] = useState(searchParams.get("recType") ? Number(searchParams.get("recType")) : null);
  const navigate = useNavigate();
  const selectedRecType = recTypeStore.getSelectedRecType();
  const recType = recTypeStore.getSelectedRecType();

  const selectedRecTypeName = selectedRecType ? selectedRecType.name : "";
  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
  const [clientsAndAccounts, setClientsAndAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [recList, setRecList] = useState([]);
  const [selectedRec, setSelectedRec] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarStartDate, setCalendarStartDate] = useState(selectedDate);
  const teamId = teamStore.getSelectedTeam().id;

  const fetchAccountsForRecType = async (teamId, recTypeId, selectedRecType, accId) => {
    const response = await recTypeStore.fetchClientsAccountsPerRecType(teamId, recTypeId);
    if (response.status === 200) {
      setClientsAndAccounts(response.data);
      populateFundTable(selectedDate, selectedRecType, response.data);

      if (accId) {
        let accounts = [];
        response.data.forEach((data) => {
          data.accountList.forEach((accountObj) => {
            accounts = [...accounts, accountObj.account];
          });
        });
        const acc = accounts.find((it) => it.uuid === accId);
        setTableRowSelected(acc.name, acc.uuid, acc.id);
      }
    }
  };

  /**
   * Call backend to get the list of recs for an account within a date range & update Calendar
   * To be used when:
   * - User clicks on fund in the dashboard table
   * - Month/year is changed on the calendar
   * - 'Today' button is clicked
   * @param {*} initialDate
   * @param {*} accountUuid
   */
  const updateCalendar = async (initialDate, accountUuid, recDate = null, recTypeId = recType.id) => {
    const startDate = getFirstDayOfMonth(initialDate);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    let data = {
      dateFrom: stringifyDate(startDate),
      dateTill: stringifyDate(endDate),
      accountList: [accountUuid],
      recTypeId: recTypeId,
    };
    const updatedRecList = await recStore.getRecsInDateRange(teamStore.getSelectedTeam().id, data);
    let recs = updatedRecList.map((accountAndRec) => accountAndRec.rec);
    recs = recs.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    setRecList(recs);

    //Update selected rec when changing fund, or clicking 'today'
    if (recDate) {
      selectRec(recDate, accountUuid, recs);
    }
  };

  const pollRecStatus = async (date, accountId, statuses = [STATUS.DRAFT.status]) => {
    if (window.location.href.includes("/recTypeDash?")) {
      const resp = await recStore.pollRecStatus(teamStore.getSelectedTeam().id, selectedRec.id);
      if (resp.status === 200 && statuses.includes(resp.data.status) && date === selectedDate && accountId === selectedAccount.id) {
        refreshDashboard(false);
      } else {
        setTimeout(() => {
          pollRecStatus(date, accountId, statuses);
        }, 5000);
      }
    }
  };

  const refreshDashboard = async (poll) => {
    populateFundTableWithSelectedClient(selectedDate);
    updateCalendar(selectedDate, selectedAccount.uuid, selectedDate, recTypeId);
    if (false) {
      pollRecStatus(selectedDate, selectedAccount.id);
    }
  };

  const initialise = async () => {
    setFundRowsInitialised(true);
    const recTypeId = searchParams.get("recType") ? Number(searchParams.get("recType")) : null;
    const resp = await recTypeStore.fetchRecTypeAndSettings(searchParams.get("teamId"), recTypeId);
    const selectedRecType = resp.status === 200 ? resp.data : null;
    const accId = searchParams.get("accId") ? searchParams.get("accId") : null;
    if (accId) {
      setCalendarOpen(true);
      updateCalendar(selectedDate, accId, selectedDate, recTypeId);
    }
    fetchAccountsForRecType(teamId, recTypeId, selectedRecType, accId);
    setRecTypeId(recTypeId);
  };

  if (
    (!fundRowsInitialised && clientStore.getAllClients().length && recTypes.length) ||
    (recTypeId !== Number(searchParams.get("recType")) && recTypes.length)
  ) {
    initialise();
  }

  const cols = {
    overview: [
      { headerName: "Client", field: "client" },
      { headerName: "Fund", field: "accountName" },
      {
        headerName: "Status",
        field: "recStatus",
        cellRenderer: (params) => {
          return params.data.recStatus === "-" ? (
            <StatusBadge status={params.data.recStatus} onClick={() => {}} recStatus={true} />
          ) : (
            <Link
              className="text-decoration-none"
              to={`${PATH.REC}?teamId=${teamId}&recTypeId=${recTypeId}&accId=${params.data.accountId}&recId=${params.data.recId}`}
            >
              <StatusBadge status={params.data.recStatus} onClick={() => {}} recStatus={true} />
            </Link>
          );
        },
      },
      {
        headerName: "",
        field: "calendarButton",
        cellRenderer: (params) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                calendarIconClick(params.data);
              }}
            >
              {selectedAccount && selectedAccount.id === params.data.accountId ? (
                <IconCalendarSelected className="btn-sm-svg" />
              ) : (
                <IconCalendar className="btn-sm-svg" />
              )}
            </div>
          );
        },
      },
    ],
  };

  const calendarIconClick = (paramsData) => {
    if (selectedAccount && selectedAccount.id === paramsData.accountId) {
      setCalendarOpen(false);
      setSelectedAccount(null);
      updateUrl(selectedDate, null);
    } else {
      setCalendarOpen(true);
      setTableRowSelected(paramsData.accountName, paramsData.accountUuid, paramsData.accountId);
      updateCalendar(calendarStartDate, paramsData.accountUuid, selectedDate);
    }
  };

  const updateUrl = (date, accUuId = null, recType = recTypeId) => {
    //Only update table if date has been changed
    if (date !== selectedDate) {
      populateFundTableWithSelectedClient(date);
      setSelectedDate(date);
      setCalendarStartDate(date);
    }
    navigate(`${PATH.REC_TYPE_DASH}?teamId=${teamId}&recType=${recType}&date=${stringifyDate(date)}${accUuId ? `&accId=${accUuId}` : ""}`, {
      replace: true,
    });
  };

  const getRecs = async (date, selectedRecType) => {
    let accountList = recTypeStore.getAccounts();
    let data = {
      dateFrom: stringifyDate(date),
      dateTill: stringifyDate(date),
      accountList: accountList.map((it) => it.uuid),
      recTypeId: selectedRecType.id,
    };
    return accountList.length ? recStore.getRecsInDateRange(teamId, data) : [];
  };

  const populateFundTableWithSelectedClient = (date) => {
    populateFundTable(date, selectedRecType, clientsAndAccounts);
  };

  const populateFundTable = async (date, selectedRecType, tableData) => {
    setFundRows(null);
    const data = await getRecs(date, selectedRecType);
    let fundsData = [];
    tableData.forEach((clientAccounts, index) => {
      const accounts = clientAccounts.accountList;
      accounts.forEach((accountData, index2) => {
        const account = accountData.account;
        const recs = data.find((obj) => obj.account === account.uuid);
        const recStatus = recs && recs.rec && recs.rec.fourEyes ? recs.rec.fourEyes.status : "-";
        const recId = recs ? recs.rec.id : null;
        fundsData.push({
          id: `${index}${index2}`,
          client: clientAccounts.client.name,
          clientId: clientAccounts.client.id,
          accountUuid: account.uuid,
          accountId: account.id,
          accountName: account.name,
          recStatus: recStatus,
          recId: recId,
        });
      });
    });
    setFundRows(fundsData);
  };

  /**
   * Sets the selected account and highlights the correspnding row in the ag-grid table
   * @param {*} accountName
   * @param {*} accountUuid
   */
  const setTableRowSelected = (accountName, accountUuid, accountId) => {
    setSelectedAccount({ name: accountName, uuid: accountUuid, id: accountId });
    gridRef.current.api.forEachNode((node) => {
      if (node.data.accountUuid === accountUuid) {
        node.setSelected(true);
      }
    });
  };

  /** Selects a rec on a date and displays its relevant data in the calendar panel */
  const selectRec = async (date, accUuId = null, recs = recList) => {
    const rec = recs.find((it) => {
      return new Date(it.startDate).toDateString() === date.toDateString();
    });
    let uploadedFiles = [];
    if (rec) {
      let resp = await recStore.getUploadedFilesForRec(teamId, rec.id);
      uploadedFiles = resp.length ? resp.filter((file) => file.status !== "DELETED") : resp;
    }

    setSelectedRec(rec ? rec : null);
    setUploadedFiles(uploadedFiles);
    updateUrl(date, accUuId);
  };

  const mainToolbar = () => {
    return (
      <R props="pt-24 pl-16">
        {calendarOpen ? (
          <Text size="md">{selectedDate ? getDayString(selectedDate) : ""}</Text>
        ) : (
          <DatePicker date={selectedDate} onDateChange={(date) => updateUrl(date)} dateFormat={meStore.getUserDateFormat()} />
        )}
      </R>
    );
  };

  const createFileName = () => {
    const fileName = `${selectedRecTypeName} Reconciliations - ${stringifyDate(selectedDate)}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <>
      <MainContainer>
        <PageTitleArea
          backButtonEnabled={false}
          title={selectedRecTypeName}
          description={`${selectedRecTypeName} Reconciliations`}
          borderBottom={false}
          props={
            <>
              <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_UPLOAD_FILE])}>
                <Button
                  size="md"
                  onClick={() => {
                    modalInstance(MODALS.MANUAL_UPLOAD).show();
                  }}
                  disabled={false}
                  color="primary-secondary"
                >
                  <Text size="sm" weight="medium">
                    Upload a file
                  </Text>
                </Button>
              </AuthWrapper>
              <span className="ml-16"></span>
              <ToolTip text="Download Dashboard" direction="left" size="large">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    gridRef.current.api.exportDataAsCsv({ fileName: createFileName() }); //getParams())
                  }}
                >
                  <IconDownload className="btn-lg-svg" />
                </span>
              </ToolTip>
              <span className="ml-16 mr-16"></span>
            </>
          }
        />
        <ManageLayout mainContentToolbar={mainToolbar()} panelHeader={<></>} pageTitleArea={<></>} panelTabs={<></>}>
          <div className="pt-32"></div>
          <Table columnDefs={cols.overview} rowData={fundRows} ref={gridRef} colFlex={true} rowSelection={"single"} />
          <ManualFileUploadModal teamId={teamId} recType={selectedRecType} selectedDate={selectedDate} />
        </ManageLayout>
        <SetAsDayOneModal
          initialDate={selectedDate}
          teamId={teamId}
          recType={selectedRecType}
          selectedFund={selectedAccount}
          refreshDashboard={refreshDashboard}
        />
        <SkipToDateModal initialDate={selectedDate} refreshDashboard={refreshDashboard} />
        <ReopenModal selectedRec={selectedRec} refreshDashboard={refreshDashboard} />
      </MainContainer>

      <AnimatedPanel open={calendarOpen}>
        <DashboardCalendar
          selectedRec={selectedRec}
          updateCalendar={updateCalendar}
          selectedDate={selectedDate}
          selectedAccount={selectedAccount}
          recList={recList}
          selectRec={selectRec}
          uploadedFiles={uploadedFiles}
          setCalendarStartDate={setCalendarStartDate}
        />
      </AnimatedPanel>
    </>
  );
});

export { RecTypeDash };
