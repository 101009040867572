import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal, ModalBody, modalInstance, Button, Text, ModalFooter, R, DatePicker, Toggle } from "@fundrecs/ui-library";
import { MODALS } from "../../../utils/enums";
import { ModalHeader } from "../../layout/Modals";
import { useStore } from "../../../store/Store";

const SkipToDateModal = observer(({ initialDate }) => {
  const { meStore } = useStore();
  const [selectedDate, setSelectedDate] = useState(null);
  const [mergeData, setMergeData] = useState(false);

  const modalId = MODALS.SKIP_TO_DATE;

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalHeader
          title="Skip to date"
          onCloseClick={() => {
            modalInstance(modalId).toggle();
          }}
        />
      </ModalBody>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <R>
          <Text size="sm" weight="regular">
            Skip performing reconciliations between the last Completed reconciliation and the selected date for the chosen fund
          </Text>
        </R>
        <R>
          <span className="mt-32"></span>
          <Text size="sm" weight="regular">
            When days are skipped the following will happen:
          </Text>
        </R>
        <div className="d-flex mt-16 ml-16">
          <Text size="sm" weight="bold" element="div">
            1.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>Dates between reconciliations will be marked as "Skipped":</b> Any dates between the last completed reconciliation and the new reconciliation
            date will have a status of "Skipped," and no new data can be uploaded to those dates.
          </Text>
        </div>
        <div className="d-flex pb-16 ml-16" style={{ borderBottom: "1px solid #efefef" }}>
          <Text size="sm" weight="bold" element="div">
            2.
          </Text>
          <span className="ml-8"></span>
          <Text size="sm" weight="regular">
            <b>Data carry forward:</b> All available data from the last completed reconciliation will automatically be carried forward to the date selected.
          </Text>
        </div>
        <div className="ml-16 pt-32 mb-16">
          <Text size="sm" weight="regular" element="div">
            Skip to a new reconciliation date:
          </Text>
          <DatePicker
            date={selectedDate ? selectedDate : initialDate}
            onDateChange={(date) => setSelectedDate(date)}
            dateFormat={meStore.getUserDateFormat()}
          />
        </div>
        <div className="d-flex ml-16 pt-16 pb-16">
          <Toggle
            disabled={false}
            checked={mergeData}
            onChange={() => {
              setMergeData(!mergeData);
            }}
          />

          <div className="ml-16">
            <Text size="sm" weight="bold" element="div">
              Merge data
            </Text>
            <Text size="xs" weight="regular" element="div">
              Merge any data currently uploaded to any date between the last Completed reconciliation and the selected date and then move this merged data to
              the selected date
            </Text>
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            modalInstance(modalId).toggle();
          }}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            modalInstance(modalId).toggle();
          }}
        >
          <Text>OK</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { SkipToDateModal };
