import { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  Modal,
  ModalBody,
  modalInstance,
  Button,
  Text,
  Dropdown,
  DropdownButton,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  ModalFooter,
  R,
} from "@fundrecs/ui-library";
import { MODALS } from "../../../../utils/enums";
import { ModalHeader } from "../../../layout/Modals";
import { AccordianPanel } from "../../../reusable/AccordianPanel";
import { useStore } from "../../../../store/Store";
import { AggregatorPanel } from "./AggregatorPanel";
import { TagsPanel } from "./TagsPanel";
import { CommentPanel } from "./CommentPanel";
import { MatchingContext, MatchingDispatchContext, RESET, SET_CATEGORY } from "../../matchingReducer";

const ManualMatchModal = observer(({ categoryNotes, nonCategoryNotes, manuallyCreateRowGroup }) => {
  const { recTypeStore, uiStore } = useStore();
  const modalId = MODALS.CREATE_MANUAL_MATCH;
  const tmos = recTypeStore.getRecTypeTmos();
  const matchOptions = useContext(MatchingContext);
  const aggregators = matchOptions["aggregators"];
  const dispatch = useContext(MatchingDispatchContext);

  const closeModalAndResetFields = () => {
    dispatch({ type: RESET });
    modalInstance(modalId).toggle();
  };

  const verifyInputs = async () => {
    if (!matchOptions.category) {
      uiStore.addNotification("error", "Please select a match/break category");
      return;
    }

    if (tmos.filter((tmo) => Object.keys(aggregators).includes(tmo.id.toString()) && aggregators[tmo.id] !== null).length !== tmos.length) {
      uiStore.addNotification("error", "Please select an aggregator for each tmo");
      return;
    }

    manuallyCreateRowGroup(formatMatchOptions());
    closeModalAndResetFields();
  };

  const formatMatchOptions = () => {
    const requestBody = {
      aggregatorSet: Object.values(aggregators).map((aggregator) => aggregator.id),
      categoryId: matchOptions.category.id,
      tagSet: matchOptions.tags.map((it) => it.id),
      rowIdSet: [],
      toBePersisted: false,
      proceedAsRows: matchOptions.proceedAsRows,
      comment: matchOptions.comment,
    };
    return requestBody;
  };

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalHeader
          title="Make a manual match"
          onCloseClick={() => {
            closeModalAndResetFields();
          }}
        />
      </ModalBody>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <R props="mt-32">
          <Text weight="bold" size="xs">
            Selecting Category
          </Text>
          <Dropdown>
            <DropdownButton disabled={false} size="sm">
              {matchOptions.category ? matchOptions.category.text : "Select a category"}
              <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
            </DropdownButton>

            <DropdownList>
              {categoryNotes.map((note, index) => {
                return (
                  <DropdownListItem
                    key={Math.random()}
                    onClick={() => {
                      dispatch({ type: SET_CATEGORY, category: note });
                    }}
                  >
                    <DropdownItem active={false} index={index}>
                      <ItemBody>{note.text}</ItemBody>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </Dropdown>
        </R>
        <AccordianPanel
          title={
            <Text size="xs" weight="regular">
              <b>Flatten</b> Choose how the selected rows should be flattened
            </Text>
          }
          openDefault={true}
        >
          <AggregatorPanel />
        </AccordianPanel>
        <AccordianPanel
          title={
            <Text size="xs" weight="regular">
              <b>Tags</b> Add additional tags
            </Text>
          }
        >
          <TagsPanel tags={nonCategoryNotes} />
        </AccordianPanel>
        <AccordianPanel
          title={
            <Text size="xs" weight="regular">
              <b>Comments</b> Add additional comments
            </Text>
          }
        >
          <CommentPanel />
        </AccordianPanel>
      </div>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            closeModalAndResetFields();
          }}
        >
          <Text>Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            verifyInputs();
          }}
          disabled={false}
        >
          <Text>Preview</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export { ManualMatchModal };
