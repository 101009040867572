import { useState, useRef, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import {
  Text,
  R,
  C4,
  ToolTip,
  IconDownload,
  Button,
  modalInstance,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconDownArrow,
} from "@fundrecs/ui-library";
import { useStore } from "../../store/Store.js";
import { formatMatchedRowsForTable } from "./recTableLogic.js";
import { Table } from "../ag-grid/Ag-grid";
import { ManageLayout } from "../layout/Layout.js";
import { PendingRecToolbar, CompletedRecToolbar } from "./reconciliationToolbars.js";
import { AUTHORITIES, MODALS, STATUS } from "../../utils/enums.js";
import { ALL_ROWS, getLastPageNo, ROW_FETCH_LIMIT } from "../../utils/rowBatchUtils.js";
import { CalendarPanel } from "./sidePanels/CalendarPanel.js";
import { UploadedFilesPanel } from "./UploadedFilesPanel.js";
import { RunningMatchingRulesPlaceholder } from "./matchingRules/RunningMatchingRulesPlaceholder.js";
import { PendingGlobalMappingsAlert } from "../globalMapping/PendingGlobalMappingsAlert.js";
import { getDateStringFromTimestamp } from "../../utils/dates.js";
import { AuthWrapper } from "../AuthorizationWrapper.js";
import { WarningModal } from "../WarningModal.js";
import { RowBatchSelector } from "./toolbars/RowBatchSelector.js";
import { ColumnViewEditor } from "./ColumnViewEditor.js";

const PendingCompletedRecView = observer(
  ({ selectedAccount, uploadedFiles = [], pollRecStatus, globalMappingsEnabledForAccount, globalMapping, subAccounts = [], rowGroupSchema = [] }) => {
    const gridRef = useRef(null);
    const { recStore, recTypeStore, meStore, matchingRuleStore, rolesStore } = useStore();
    const [searchParams] = useSearchParams();
    const accountId = Number(searchParams.get("accId"));
    const recTypeId = Number(searchParams.get("recTypeId"));
    const teamId = Number(searchParams.get("teamId"));
    const rec = recStore.getSelectedRec();
    const recType = recTypeStore.getSelectedRecType();
    const recStatus = rec.fourEyes && rec.fourEyes.status ? rec.fourEyes.status : "";
    const matchingRules = matchingRuleStore.getMatchingRules();
    const rejectApproveDisabled = !rec || recStatus !== STATUS.PENDING.status || meStore.getEmail() === rec.fourEyes.submittedBy.userName;
    const [rows, setRows] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [selectedSubAccount, setSelectedSubAccount] = useState(null);
    const ALL_SUB_ACCOUNTS = useMemo(() => ({ name: "All sub accounts", id: null }), []);
    const [displayNumberOfRows, setDisplayNumberOfRows] = useState(ROW_FETCH_LIMIT);
    const [rowCountsForTable, setRowCountsForTable] = useState(null);

    const [initialised, setInitialised] = useState(false);

    const rejectRec = async () => {
      const rec = recStore.getSelectedRec();
      recStore.rejectRec(teamId, rec.id, rec.version);
    };

    const approveRec = async () => {
      const rec = recStore.getSelectedRec();
      recStore.approveRec(teamId, rec.id, rec.version);
    };

    const reopenRec = async () => {
      const rec = recStore.getSelectedRec();
      recStore.reopenRec(teamId, rec.id, rec.version);
    };

    const cancelReport = async () => {
      if (recStatus === STATUS.REPORT_CREATE.status) {
        const rec = recStore.getSelectedRec();
        recStore.cancelReport(teamId, rec.id, rec.version);
        modalInstance(MODALS.WARNING).hide();
      }
    };

    if (recStatus === STATUS.REPORT_CREATE.status) {
      pollRecStatus();
    }

    const fetchRowCounts = async () => {
      let counts = {};
      for (let i = 0; i < subAccounts.length; i++) {
        const rowCountForTableResponse = await recStore.fetchRowCountForTable(teamId, rec.id, subAccounts[i].id);
        if (rowCountForTableResponse.length) {
          counts[subAccounts[i].id] = rowCountForTableResponse[0]["rowGroupsCount"];
        }
      }
      setRowCountsForTable(counts);
    };

    const fetchRows = async (subAccount, numberOfRows = displayNumberOfRows) => {
      setRows(null);
      numberOfRows =
        numberOfRows === ALL_ROWS ? (subAccount.name === ALL_SUB_ACCOUNTS.name ? renderTotalRowCount : rowCountsForTable[subAccount.id]) : numberOfRows;
      let rowGroups = [];
      /*
      const fetch = (pageNo) => {
        return subAccount.name === ALL_SUB_ACCOUNTS.name
          ? recStore.getRowGroupsForAllSubAccounts(teamId, rec.id, rec.version, pageNo)
          : recStore.getRowGroupsForSubAccount(teamId, rec.id, subAccount.uuid, rec.version, pageNo);
      };

      const responses = await fetchBatches(numberOfRows, (pageNo) => fetch(pageNo));
      responses.forEach((resp) => (rowGroups = [...rowGroups, ...resp]));
      */
      //Above commented logic sends the requests concurently, rather than the loop below which sends them consecutively
      for (let pageNo = 0; pageNo <= getLastPageNo(numberOfRows); pageNo++) {
        const resp =
          subAccount.name === ALL_SUB_ACCOUNTS.name
            ? await recStore.getRowGroupsForAllSubAccounts(teamId, rec.id, rec.version, pageNo)
            : await recStore.getRowGroupsForSubAccount(teamId, rec.id, subAccount.uuid, rec.version, pageNo);
        rowGroups = [...rowGroups, ...resp];
      }

      const tableData = formatMatchedRowsForTable(rowGroupSchema, rowGroups, matchingRules);
      setRows(tableData.rows);
      setHeaders(tableData.columns);
      setSelectedSubAccount(subAccount);
    };

    const downloadFile = async () => {
      gridRef.current.api.exportDataAsCsv({ fileName: createFileName() });
    };

    const createFileName = () => {
      const fileName = `${recType.name} reconciliation (${STATUS[rec.fourEyes.status]["text"]})- ${selectedAccount.name}, ${
        selectedSubAccount ? selectedSubAccount.name : ""
      } - ${getDateStringFromTimestamp(rec.startDate)}`;
      const fullStopsRemoved = fileName.replaceAll(".", "");
      return fullStopsRemoved;
    };

    if (!initialised && rec && recType && subAccounts.length && rowGroupSchema.length) {
      setInitialised(true);
      fetchRows(ALL_SUB_ACCOUNTS);
      fetchRowCounts();
    }

    const updateTable = async (numberOfRows = displayNumberOfRows) => {
      if (numberOfRows !== displayNumberOfRows) {
        setDisplayNumberOfRows(numberOfRows);
      }
      fetchRows(selectedSubAccount, numberOfRows);
    };

    const renderTotalRowCount = useMemo(() => {
      let total = 0;
      if (rowCountsForTable) {
        Object.values(rowCountsForTable).forEach((count) => (total += count));
      }
      return total;
    }, [rowCountsForTable]);

    const renderRowCount = useMemo(() => {
      return rowCountsForTable && selectedSubAccount
        ? selectedSubAccount.name === ALL_SUB_ACCOUNTS.name
          ? renderTotalRowCount
          : rowCountsForTable[selectedSubAccount.id]
        : "";
    }, [selectedSubAccount, renderTotalRowCount, ALL_SUB_ACCOUNTS, rowCountsForTable]);

    return (
      <>
        <ManageLayout
          headerTabs={
            <div className="dropdown" style={{ width: "300px" }}>
              <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
                <span>{selectedSubAccount ? selectedSubAccount.name : ""}</span>
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </div>
              <DropdownList>
                {[ALL_SUB_ACCOUNTS, ...subAccounts].map((subAccount, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        fetchRows(subAccount);
                      }}
                      key={index + 1}
                    >
                      <DropdownItem active={null} index={0}>
                        <ItemBody title={subAccount.name}>{subAccount.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </div>
          }
          rightToolbar={
            recStatus === STATUS.PENDING.status ? (
              <PendingRecToolbar teamId={teamId} rejectRec={rejectRec} rejectApproveDisabled={rejectApproveDisabled} approveRec={approveRec} />
            ) : recStatus === STATUS.APPROVED.status ? (
              <CompletedRecToolbar teamId={teamId} reopenDisabled={false} reopenRec={reopenRec} />
            ) : recStatus === STATUS.REPORT_CREATE.status ? (
              <div className="mb-16">
                <AuthWrapper
                  teamId={teamId}
                  allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_SUBMIT, AUTHORITIES.RECS_REC_APPROVE, AUTHORITIES.RECS_REC_REJECT])}
                >
                  <Button
                    size="md"
                    onClick={() => {
                      modalInstance(MODALS.WARNING).show();
                    }}
                    disabled={recStatus !== STATUS.REPORT_CREATE.status}
                    color="danger"
                  >
                    <Text size="sm" weight="medium">
                      Cancel
                    </Text>
                  </Button>
                </AuthWrapper>
                <WarningModal
                  modalId={MODALS.WARNING}
                  title="Are you sure you want to cancel rec approval?"
                  description="This action will cancel any reports currently being generated and return the rec status to open"
                  buttonClick={() => {
                    cancelReport();
                  }}
                  buttonText="Cancel"
                />
              </div>
            ) : (
              ""
            )
          }
        >
          {recStatus === STATUS.REPORT_CREATE.status ? <RunningMatchingRulesPlaceholder text="Approving reconciliation" /> : ""}
          <PendingGlobalMappingsAlert
            teamId={teamId}
            recTypeId={recTypeId}
            accountId={accountId}
            globalMappingId={globalMappingsEnabledForAccount ? globalMapping.id : null}
          />
          <R props="mb-8 pl-0 pr-0 pt-16">
            <C4 props="pl-0">
              <ColumnViewEditor gridRef={gridRef} recTypeId={recTypeId} teamId={teamId} selectedTab="Processed" />
            </C4>
            <C4 props="text-center">
              <RowBatchSelector
                rowCount={renderRowCount}
                displayNumberOfRows={displayNumberOfRows}
                fetchRows={async (option) => {
                  await updateTable(option);
                }}
              />
            </C4>
            <C4 props="text-right pr-0">
              <ToolTip text="Download Table" direction="left" size="large">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    downloadFile();
                  }}
                >
                  <IconDownload className="btn-lg-svg" />
                </span>
              </ToolTip>
            </C4>
          </R>
          <Table columnDefs={headers} rowData={rows} ref={gridRef} height="60vh" />
        </ManageLayout>
        <CalendarPanel selectedDate={rec.startDate} selectedAccount={selectedAccount} />
        <UploadedFilesPanel
          uploadedFiles={uploadedFiles}
          accountName={selectedAccount.name}
          date={getDateStringFromTimestamp(rec.startDate)}
          matchingRulesRunning={true}
        />
      </>
    );
  }
);

export { PendingCompletedRecView };
