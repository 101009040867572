import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import {
  Text,
  Button,
  modalInstance,
  PanelFooter,
  Heading,
  R,
  C6,
  TextArea,
  Input,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownListItem,
  ItemBody,
  IconCheck,
} from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { SlideOverPanel } from "../../reusable/SlideOverPanel/SlideOverPanel";
import { AuthWrapper } from "../../AuthorizationWrapper";
import { AUTHORITIES } from "../../../utils/enums";

const CreateCarryForwardRuleModal = observer(({ title, description, modalId, manualActions = false, ruleToView = null, setRuleToView }) => {
  const { recTypeStore, carryForwardStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recType");
  const defaultDraftRule = {
    name: "",
    description: "",
    categories: [],
    resultsColumns: [],
    tmoColumns: {},
    actionSet: [],
  };
  const [draftRule, setDraftRule] = useState(defaultDraftRule);
  const [carryForwardActions, setCarryForwardActions] = useState([]);
  const [initialised, setInitialised] = useState(false);

  const tmos = recTypeStore.getRecTypeTmos();
  const tmoColumns = recTypeStore.getRecTypeColumnMap();
  const resultsColumns = recTypeStore.getGroupTmoColumns();
  const recTypeTags = recTypeStore.getRecTypeTags().filter((it) => it.noteType === "CATEGORY");
  const recType = recTypeStore.getSelectedRecType();
  const nameMaxLength = 100;
  const descriptionMaxLength = 150;

  const getCarryActions = async () => {
    const resp = await carryForwardStore.getCarryActions(teamId, recTypeId);
    if (resp.status === 200) {
      setCarryForwardActions(resp.data);
    }
  };

  if (!initialised && teamId && recTypeId) {
    setInitialised(true);
    getCarryActions();
  }

  if (ruleToView && !draftRule.name) {
    let rule = {
      name: ruleToView.name,
      description: ruleToView.description,
      categories: ruleToView.categorySet.map((it) => {
        return { text: it.name, ...it };
      }),
      actionSet: ruleToView.carryActions,
      resultsColumns: [],
      tmoColumns: {},
    };

    if (manualActions) {
      let tmoColumns = {};
      Object.entries(ruleToView.groupBy).forEach((entry) => {
        tmoColumns[entry[0]] = entry[1].map((it) => {
          return { displayName: it.name, ...it };
        });
      });
      rule.tmoColumns = tmoColumns;
    } else {
      rule.resultsColumns = ruleToView.groupBy;
    }
    setDraftRule(rule);
  }

  const toggleSelection = (selected, option, key = "id") => {
    selected = [undefined, null].includes(selected) ? [] : selected;
    const alreadySelected = selected && selected.length ? selected.filter((it) => it[key] === option[key]) : [];
    return alreadySelected.length ? selected.filter((it) => it[key] !== option[key]) : [...selected, option];
  };

  const closeModalAndClearFields = () => {
    setRuleToView(null);
    setDraftRule(defaultDraftRule);
    modalInstance(modalId).hide();
  };

  const selectResultsColumn = (column) => {
    setDraftRule({ ...draftRule, resultsColumns: toggleSelection(draftRule.resultsColumns, column, "name") });
  };

  const selectTmoColumn = (tmo, column) => {
    setDraftRule({ ...draftRule, tmoColumns: { ...draftRule.tmoColumns, [tmo.id]: toggleSelection(draftRule.tmoColumns[tmo.id], column) } });
  };

  const selectAction = (action) => {
    setDraftRule({ ...draftRule, actionSet: toggleSelection(draftRule.actionSet, action) });
  };

  const selectCategory = (category) => {
    let newCategories = [];
    if (!manualActions) {
      newCategories = toggleSelection(draftRule.categories, category);
    } else {
      newCategories = [category];
    }
    setDraftRule({ ...draftRule, categories: newCategories });
  };

  const generateColumnsString = (selectedColumns, key, defaultText) => {
    let str = "";

    if (selectedColumns && selectedColumns.length) {
      selectedColumns.forEach((category, index) => {
        str += `${category[key]}${index < selectedColumns.length - 1 ? ", " : " "}`;
      });
    } else {
      str = defaultText;
    }

    return str;
  };

  const createNewRule = async () => {
    const body = {
      recTypeId: recType.id,
      recTypeVersion: recType.version,
      categorySet: draftRule.categories.map((it) => it.id),
      actionSet: draftRule.actionSet.map((it) => it.id),
      name: draftRule.name,
      description: draftRule.description,
    };
    let resp = null;

    const convertColumns = (columns) => {
      return columns.map((col) => {
        return { name: col.name, type: "column" };
      });
    };

    if (manualActions) {
      let groupBy = {};
      Object.entries(draftRule.tmoColumns).forEach((entry) => {
        groupBy[entry[0]] = convertColumns(entry[1]);
      });

      body.groupBy = groupBy;
      resp = await carryForwardStore.createCarryForwardManualActions(teamId, body);
    } else {
      body.groupBy = convertColumns(draftRule.resultsColumns);
      resp = await carryForwardStore.createCarryForwardAutoGroups(teamId, body);
    }

    if (resp && resp.status === 200) {
      carryForwardStore.fetchCarryForwardRules(teamId, recTypeId);
      closeModalAndClearFields();
    }
  };

  const canEdit = ruleToView === null;

  return (
    <SlideOverPanel width="75%" id={modalId}>
      <div className="panel-content" style={{ overflowX: "scroll" }}>
        <div className="panel-header">
          <div className="panel-header-left">
            <Heading variant="h4" element="span">
              {title}
            </Heading>
          </div>
          <div className="panel-header-right">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalAndClearFields}></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <Text variant="secondary" weight="normal" size="sm">
          {description}
        </Text>
        <R props="mt-32">
          <C6 props="pl-0">
            <div className="mb-8" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ alignSelf: "flex-start" }}>
                <Text size="sm" weight="medium" variant="secondary">
                  Rule name *
                </Text>
              </div>
              <div style={{ alignSelf: "flex-end" }}>
                <Text size="sm" weight="regular" variant="tertiary" element="div">{`${draftRule.name.length}/${nameMaxLength}`}</Text>
              </div>
            </div>
            <Input
              onChange={(event) => {
                if (event.target.value.length <= nameMaxLength) {
                  setDraftRule({ ...draftRule, name: event.target.value });
                }
              }}
              value={draftRule.name}
              disabled={!canEdit}
            />
            <div className="mb-32"></div>
            <div className="mb-8" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ alignSelf: "flex-start" }}>
                <Text size="sm" weight="medium" variant="secondary">
                  Rule description *
                </Text>
              </div>
              <div style={{ alignSelf: "flex-end" }}>
                <Text size="sm" weight="regular" variant="tertiary" element="div">{`${draftRule.description.length}/${descriptionMaxLength}`}</Text>
              </div>
            </div>

            <TextArea
              disabled={!canEdit}
              onChange={(event) => {
                if (event.target.value.length <= descriptionMaxLength) {
                  setDraftRule({ ...draftRule, description: event.target.value });
                }
              }}
              value={draftRule.description}
              warning={false}
              warningMessage={null}
            />
            <div className="mt-32 mb-8">
              <Text size="sm" weight="medium" variant="secondary">
                Reconciliation Type
              </Text>
            </div>
            <Text size="sm" weight="bold" variant="secondary">
              {recType ? recType.name : ""}
            </Text>
          </C6>
          <C6>
            <Text size="sm" weight="medium" variant="muted">
              {`CARRY FORWARD ${manualActions ? "THE FOLLOWING CATEGORY" : ""}`}
            </Text>
            <div className="mt-16">
              <ColumnsDropdown
                title={`Select categor${manualActions ? "y" : "ies"}`}
                columns={recTypeTags}
                keyName="text"
                onClick={(column) => selectCategory(column)}
                displayText={generateColumnsString(draftRule.categories, "text", "Select categories")}
                currentSelection={draftRule.categories}
                disabled={!canEdit}
              />
            </div>
            <div className="mt-32">
              <Text size="sm" weight="normal" variant="muted">
                Select what data should be compared in order to identify identical row data
              </Text>
            </div>
            <R props="pl-0 mt-8 pb-32">
              {!manualActions ? (
                <ColumnsDropdown
                  title="Results columns:"
                  columns={resultsColumns}
                  keyName="name"
                  onClick={(column) => selectResultsColumn(column)}
                  displayText={generateColumnsString(draftRule.resultsColumns, "name", "Select results columns")}
                  currentSelection={draftRule.resultsColumns}
                  disabled={!canEdit}
                />
              ) : (
                <>
                  {tmos.map((tmo) => {
                    return (
                      <ColumnsDropdown
                        title={`${tmo.name} columns:`}
                        columns={tmoColumns[tmo.id]}
                        keyName="displayName"
                        onClick={(column) => selectTmoColumn(tmo, column)}
                        displayText={generateColumnsString(draftRule.tmoColumns[tmo.id], "displayName", `Select ${tmo.name} columns`)}
                        currentSelection={draftRule.tmoColumns[tmo.id]}
                        disabled={!canEdit}
                      />
                    );
                  })}
                </>
              )}
            </R>
            <div className="mt-32">
              <Text size="sm">What do you want to carry forward?</Text>
              {carryForwardActions.map((action) => {
                return (
                  <CheckboxAndText
                    title={action.name}
                    description={action.description}
                    onClick={() => {
                      selectAction(action);
                    }}
                    checked={draftRule.actionSet.find((it) => it.id === action.id)}
                    disabled={!canEdit}
                  />
                );
              })}
            </div>
          </C6>
        </R>
      </div>
      <PanelFooter>
        {canEdit ? (
          <>
            <AuthWrapper
              teamId={teamId}
              oneRequired={rolesStore.getActions([AUTHORITIES.RECS_CARRY_MANUAL_GROUP_CREATE, AUTHORITIES.RECS_CARRY_AUTO_GROUP_CREATE])}
            >
              <Button onClick={createNewRule}>
                <Text weight="regular">Create rule</Text>
              </Button>
            </AuthWrapper>
            <Button color="tertiary" onClick={closeModalAndClearFields}>
              <Text>Cancel</Text>
            </Button>
          </>
        ) : (
          ""
        )}
      </PanelFooter>
    </SlideOverPanel>
  );
});

const CheckboxAndText = ({ title, description, onClick, checked = false, disabled = false }) => {
  return (
    <div className="pt-16 d-flex">
      <div>
        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={checked} onClick={onClick} disabled={disabled} />
      </div>
      <div className="pl-16">
        <Text size="sm" element="div">
          {title}
        </Text>
        <Text size="xs" variant="muted" weight="normal">
          {description}
        </Text>
      </div>
    </div>
  );
};

const ColumnsDropdown = ({ title, displayText, columns, keyName, onClick, currentSelection = [], disabled = false }) => {
  return (
    <C6 props="pl-0">
      <Text size="sm" weight="medium" variant="secondary" element="div">
        {title}
      </Text>
      <Dropdown>
        <DropdownButton size="sm" disabled={disabled}>
          <span>{displayText}</span>
        </DropdownButton>
        <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ background: "#fff", height: "200px", overflowX: "scroll" }}>
          {columns && columns.length
            ? columns
                .sort((a, b) => a[keyName].localeCompare(b[keyName]))
                .map((column, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        onClick(column);
                      }}
                      key={index + 1}
                    >
                      <DropdownItem active={null} index={0}>
                        <ItemBody>{column[keyName]}</ItemBody>
                        {currentSelection.find((it) => it[keyName] === column[keyName]) ? <IconCheck className="btn-sm-svg" /> : ""}
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })
            : ""}
        </ul>
      </Dropdown>
    </C6>
  );
};

export { CreateCarryForwardRuleModal };
