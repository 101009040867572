import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Button, R, IconDownArrow, IconAdd, HoverDropdown, Input, modalInstance } from "@fundrecs/ui-library";
import { ColumnViewPanel } from "./ColumnViewPanel";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { useStore } from "../../store/Store";
import { AuthWrapper } from "../AuthorizationWrapper";

const ColumnViewEditor = observer(({ gridRef, recTypeId, teamId, selectedTab }) => {
  const unmatchedText = "Unprocessed";
  const processedText = "Processed";
  const { viewProfileStore, rolesStore } = useStore();
  const [selectedColumnSetting, setSelectedColumnSetting] = useState(null);
  const [newColumnViewName, setNewColumnViewName] = useState("");
  const [rowViewProfiles, setRowViewProfiles] = useState(null);
  const [rowGroupViewProfiles, setRowGroupViewProfiles] = useState(null);

  useEffect(() => {
    if (selectedTab) {
      setSelectedColumnSetting(null);
      fetchViewProfiles();
    }
  }, [selectedTab]);

  const fetchRowViewProfiles = async () => {
    let rowProfileResponse = null;
    rowProfileResponse = await viewProfileStore.fetchRowViewProfiles(teamId, recTypeId);
    if (rowProfileResponse && rowProfileResponse.status === 200) {
      setRowViewProfiles(rowProfileResponse.data);
    }
    return rowProfileResponse;
  };

  const fetchRowGroupViewProfiles = async () => {
    let rowGroupProfileResponse = null;
    rowGroupProfileResponse = await viewProfileStore.fetchRowGroupViewProfiles(teamId, recTypeId);
    if (rowGroupProfileResponse && rowGroupProfileResponse.status === 200) {
      setRowGroupViewProfiles(rowGroupProfileResponse.data);
    }
    return rowGroupProfileResponse;
  };

  const fetchViewProfiles = async () => {
    const rowProfileResponse = await fetchRowViewProfiles();
    const rowGroupProfileResponse = await fetchRowGroupViewProfiles();
    if (rowProfileResponse && rowProfileResponse.status === 200) {
      if (selectedTab === unmatchedText) {
        findFavourite(rowProfileResponse.data);
      }
    }
    if (rowGroupProfileResponse && rowGroupProfileResponse.status === 200) {
      setRowGroupViewProfiles(rowGroupProfileResponse.data);
      if (selectedTab === processedText) {
        findFavourite(rowGroupProfileResponse.data);
      }
    }
  };

  const findFavourite = (viewProfiles) => {
    for (let i = 0; i < viewProfiles.length; i++) {
      if (viewProfiles[i]["favourite"]) {
        setViewProfile(viewProfiles[i]["id"]);
        break;
      }
    }
  };

  const setViewProfile = async (id) => {
    if (selectedTab === unmatchedText) {
      const response = await viewProfileStore.fetchRowProfileById(teamId, id);
      if (response.status === 200) {
        setColumnPreference(response.data);
        const response2 = await viewProfileStore.setFavouriteRowProfile(teamId, id);
        fetchRowViewProfiles();
      }
    } else if (selectedTab === processedText) {
      const response = await viewProfileStore.fetchRowGroupProfileById(teamId, id);
      if (response.status === 200) {
        setColumnPreference(response.data);
        const response2 = await viewProfileStore.setFavouriteRowGroupProfile(teamId, id);
        fetchRowGroupViewProfiles();
      }
    }
  };

  const createRowViewProfile = async () => {
    let columnSettings = [];
    gridRef.current.columnApi.getColumnState().forEach((column) => {
      if (!["0", "expandRow"].includes(column.colId))
        columnSettings.push({ ...column, filter: gridRef.current.api.getFilterInstance(column.colId).getModel() });
    });
    const userCreatedSetting = { name: newColumnViewName, columns: columnSettings, recTypeId: recTypeId };
    if (selectedTab === unmatchedText) {
      const response = await viewProfileStore.createRowViewProfile(teamId, userCreatedSetting);
      if (response.status === 200) {
        setViewProfile(response.data);
        setNewColumnViewName("");
      }
    } else if (selectedTab === processedText) {
      const response = await viewProfileStore.createRowGroupViewProfile(teamId, userCreatedSetting);
      if (response.status === 200) {
        setViewProfile(response.data);
        setNewColumnViewName("");
      }
    }
  };

  const resetColumnSettings = () => {
    const updatedColumns = selectedColumnSetting.columns.map((setting) => {
      return {
        ...setting,
        width: 200,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
        filter: null,
      };
    });
    setColumnPreference({ name: "", columns: updatedColumns });
  };

  const ensureSubAccountIsDisplayed = (settings) => {
    let subAccountSetting = settings.find((it) => it.colId === "Sub Account");
    if (subAccountSetting) {
      subAccountSetting.hide = false;
    }
  };

  const setColumnPreference = async (setting) => {
    let prefixCols = [];
    if (selectedTab === unmatchedText) {
      prefixCols = [{ colId: "0", width: 50 }];
    } else if (selectedTab === processedText) {
      prefixCols = [
        { colId: "0", width: 50 },
        { colId: "expandRow", width: 50 },
      ];
    }
    const settings = [...prefixCols, ...setting.columns];
    //Sub Account must be displayed
    ensureSubAccountIsDisplayed(settings);

    gridRef.current.columnApi.applyColumnState({
      state: settings,
      applyOrder: true,
    });
    settings.forEach(async (column) => {
      const filterInstance = gridRef.current.api.getFilterInstance(column.colId);
      if (filterInstance) {
        await filterInstance.setModel(column.filter);
      }
    });
    gridRef.current.api.onFilterChanged();
    setSelectedColumnSetting(setting);
  };

  const buttonStyle = {
    fontSize: "1rem",
    padding: "0.3rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.3rem",
    lineHeight: "initial",
    color: "#6A7388",
    backgroundColor: "#fff",
    border: "1px solid #CDD0D7",
    verticalAlign: "middle",
    maxHeight: "30px",
  };

  return (
    <>
      <span className="mr-8">
        <Text size="sm" weight="medium">
          View
        </Text>
      </span>
      <button disabled={false} data-bs-toggle="dropdown" type="button" style={{ ...buttonStyle, borderRadius: "0.25rem 0rem 0rem 0.25rem" }} onClick={() => {}}>
        <IconAdd className="btn-lg-svg" />
      </button>
      <div className="pt-8 pl-8 pr-8 pb-8 dropdown-menu">
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_CREATE_COLUMN_VIEW])}>
          <div className="pb-8">
            <Text variant="tertiary">CREATE A NEW VIEW</Text>
          </div>
          <div style={{ cursor: "pointer", borderTop: "1px solid #E6E8EB" }} className="pt-8">
            <Input
              onChange={(event) => {
                setNewColumnViewName(event.target.value);
              }}
              placeholder="Give view a name"
              value={newColumnViewName ? newColumnViewName : ""}
            />
          </div>

          <div className="pt-8 pb-16" style={{ borderBottom: "1px solid #E6E8EB" }}>
            <R>
              <Button
                size="md"
                onClick={() => {
                  createRowViewProfile();
                }}
                disabled={false}
                color={"tertiary"}
              >
                <Text>Save</Text>
              </Button>
            </R>
          </div>
        </AuthWrapper>
        <div className="pt-8 hover-dropdown-item">
          <R>
            <span
              onClick={() => {
                modalInstance(MODALS.COLUMN_VIEWS).show();
              }}
              disabled={false}
              color={"tertiary"}
            >
              <Text variant="link">See all views</Text>
            </span>
          </R>
        </div>
      </div>
      <button
        data-bs-toggle="dropdown"
        type="button"
        style={{ ...buttonStyle, paddingRight: "8px", paddingLeft: "8px", borderRadius: "0rem 0.25rem 0.25rem 0rem", borderLeft: "1px solid #CDD0D7" }}
      >
        <Text size="sm" weight="medium">
          {selectedColumnSetting ? selectedColumnSetting.name : ""}
        </Text>
        <span className="mr-8"></span>
        <IconDownArrow className="btn-lg-svg" />
      </button>
      <HoverDropdown>
        {selectedTab === unmatchedText && rowViewProfiles !== null
          ? rowViewProfiles.map((option) => {
              return (
                <li
                  onClick={() => {
                    setViewProfile(option.id);
                  }}
                  className="hover-dropdown-item"
                >
                  <span className="align-self-center">
                    <Text variant="primary" weight="regular" size="sm">
                      {option.name}
                    </Text>
                  </span>
                </li>
              );
            })
          : selectedTab === processedText && rowGroupViewProfiles !== null
          ? rowGroupViewProfiles.map((option) => {
              return (
                <li
                  onClick={() => {
                    setViewProfile(option.id);
                  }}
                  className="hover-dropdown-item"
                >
                  <span className="align-self-center">
                    <Text variant="primary" weight="regular" size="sm">
                      {option.name}
                    </Text>
                  </span>
                </li>
              );
            })
          : ""}
      </HoverDropdown>
      <ColumnViewPanel
        rowViewProfiles={rowViewProfiles}
        rowGroupViewProfiles={rowGroupViewProfiles}
        refreshRows={fetchViewProfiles}
        resetColumnSettings={resetColumnSettings}
      />
    </>
  );
});

export { ColumnViewEditor };
