import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Button,
  Text,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
  DropdownListItem,
  ItemBody,
  R,
  C2,
  C3,
  C4,
  C10,
  IconDownArrow,
  modalInstance,
} from "@fundrecs/ui-library";
import { ModalHeader } from "../../../layout/ModalHeader";
import { MODALS, NOTE_TYPE, STATUS } from "../../../../utils/enums";
import { useStore } from "../../../../store/Store";
import { ManageLayout, TabsLayout } from "../../../layout/Layout";
import { FunctionSelector } from "../../../rules/FunctionSelector";
import { FunctionEditor } from "../../../rules/FunctionEditor";
import { ConditionAction } from "../../../rules/ConditionAction";
import { CATEGORY, VERIFICATION, TAG, formatMatchingRuleToEdit } from "../../../rules/dataTransform";
import { PATH } from "../../../../utils/urls";
import { FullScreenView } from "../../../reusable/FullScreenView";
import { WarningModal } from "../../../WarningModal";
import { ConditionActionHelpText, FilterAndGroupHelpText, HelpSection } from "../HelpSection";
import { InfoToolTip } from "../../../reusable/InfoToolTip";
import { Groupings } from "./Groupings";
import { ifNullUndefinedArray } from "../../../../utils/utils";

/**
 * Matching Rule Builder
 * Provides a view of all parts of a matching rule & enables user to add/edit each
 */
const MatchingRuleBuilder = observer(({}) => {
  const { matchingRuleStore, comparatorFunctionStore, recTypeStore, uiStore, tmoAggregatorStore, globalMappingStore } = useStore();
  const matchingRule = matchingRuleStore.getDraftMatchingRule();
  const tmoColumns = recTypeStore.getRecTypeColumnMap();
  const groupTmoColumns = recTypeStore.getGroupTmoColumns();
  const tmos = recTypeStore.getRecTypeTmos();
  const [searchParams, setSearchParams] = useSearchParams();
  const [highlighted, setHighlighted] = useState(null);
  const [tmoAggregators, setTmoAggregators] = useState([]);
  const [initialised, setInitialised] = useState(false);
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recTypeId");
  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
  const navigate = useNavigate();

  const getTmoAggregatorsForRecType = async (recTypeId) => {
    const tmoResponse = await tmoAggregatorStore.getTmoAggregatorsForRecType(searchParams.get("teamId"), recTypeId);
    if (tmoResponse.status === 200) {
      setTmoAggregators(tmoResponse.data);
    }
  };

  const setMatchingRule = async (functions, tmos) => {
    const matchingRuleId = searchParams.get("matchingRuleId");
    const ruleDetails = await recTypeStore.fetchMatchingRuleDetails(teamId, recTypeId);
    const rule = ruleDetails.data.find((it) => it.id === Number(matchingRuleId));
    if (rule) {
      matchingRuleStore.updateDraftMatchingRule({
        ...formatMatchingRuleToEdit(rule, rule, functions, tmos),
        status: rule.state.status,
        locked: rule.state.status === STATUS.PUBLISHED.status,
      });
    }
  };

  const initialiseRuleBuilder = async () => {
    setInitialised(true);
    const functions = await comparatorFunctionStore.fetchFunctions(teamId);
    const tmosResponse = await recTypeStore.fetchTmosForRecType(teamId, recTypeId);
    await globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    getTmoAggregatorsForRecType(recTypeId);
    if (!Object.keys(matchingRule).length) {
      setMatchingRule(functions, tmosResponse.data);
    }
    recTypeStore.setSelectedRecTypeWithId(Number(teamId), Number(recTypeId));
  };

  if (!initialised && teamId && recTypeId && recTypes.length) {
    initialiseRuleBuilder();
  }

  const saveMatchingRule = async () => {
    if (
      !matchingRule.proceedAsRows &&
      matchingRule.globalMappingId === null &&
      (!matchingRule.groupings || !matchingRule.groupings.length || !Object.keys(matchingRule.groupings[0]).length || validateGroupings())
    ) {
      uiStore.addNotification("error", "Please add at least one grouping or select 'Proceed as individual rows'");
      return;
    }
    if (!matchingRule.tmoAggregatorIdMap || validateAggregators()) {
      uiStore.addNotification("error", "Please add an aggregator for each side");
      return;
    }
    if (!matchingRule.conditionsActions || matchingRule.conditionsActions.length < 1 || validateCondtionsActions()) {
      uiStore.addNotification("error", "Please complete at least one condition/action to assign a tag, match or break");
      return;
    }
    const resp = await matchingRuleStore.bulkUpdateMatchingRule(teamId);
    if (resp.status === 200) {
      closeModalAndResetFields();
    }
  };

  const validateGroupings = () => {
    let invalid = false;
    Object.entries(matchingRule.groupings[0]).forEach((grouping) => {
      if (!grouping[1]["name"] || !grouping[1]["type"]) {
        invalid = true;
        return;
      }
    });
    return invalid;
  };

  const validateAggregators = () => {
    let invalid = false;
    const tmoIds = tmos.map((tmo) => tmo.id.toString());
    tmoIds.forEach((tmoId) => {
      if (!Object.keys(matchingRule.tmoAggregatorIdMap).includes(tmoId) || [undefined, null].includes(matchingRule.tmoAggregatorIdMap[tmoId])) {
        invalid = true;
        return;
      }
    });
    return invalid;
  };

  const validateCondtionsActions = () => {
    let invalid = false;
    matchingRule.conditionsActions.forEach((conditionAction) => {
      if (
        conditionAction.conditions.length < 1 ||
        !conditionAction.conditions[0]["function"] ||
        conditionAction.actions.length !== 1 ||
        !conditionAction.actions[0]["id"]
      ) {
        invalid = true;
        return;
      }
    });
    return invalid;
  };

  const closeModalAndResetFields = () => {
    modalInstance(MODALS.WARNING).hide();
    recTypeStore.fetchMatchingRuleDetails(teamId, recTypeId);
    matchingRuleStore.clearDraftMatchingRule();
    navigate(`${PATH.REC_TYPE_CONFIG}?teamId=${teamId}&recType=${recTypeId}&tab=2`, { replace: false });
  };

  const close = () => {
    if (matchingRule.status === STATUS.PUBLISHED.status) {
      closeModalAndResetFields();
    } else {
      modalInstance(MODALS.WARNING).show();
    }
  };

  const selectTmo = (tmoId) => {
    const tmoConditions = matchingRule.conditions && matchingRule.conditions[tmoId] ? [...matchingRule.conditions[tmoId]] : [];
    const conditions = tmoId !== null ? { [tmoId]: tmoConditions } : matchingRule.conditions;
    updateDraftMatchingRule({ ...matchingRule, groupings: [], conditions: conditions, tmoId: tmoId });
  };

  const addCondition = (tmoId) => {
    const newCondition = { isNew: true, function: "", params: [] };
    const tmoConditions = matchingRule.conditions && matchingRule.conditions[tmoId] ? [...matchingRule.conditions[tmoId], newCondition] : [newCondition];
    const conditions = { ...matchingRule.conditions, [tmoId]: tmoConditions };
    updateDraftMatchingRule({ ...matchingRule, conditions: conditions });
  };

  const removeConditionAction = (index) => {
    const updatedArray = matchingRule.conditionsActions;
    updatedArray.splice(index, 1);
    updateDraftMatchingRule({ ...matchingRule, conditionsActions: updatedArray });
  };

  const updateDraftMatchingRule = (matchingRule) => {
    matchingRuleStore.updateDraftMatchingRule(matchingRule);
  };

  const updateCondition = (updatedCondition, index, tmoId) => {
    const tmoConditions = matchingRule.conditions[tmoId];
    tmoConditions.splice(index, 1, updatedCondition);
    const conditions = { ...matchingRule.conditions, [tmoId]: tmoConditions };
    updateDraftMatchingRule({ ...matchingRule, conditions: conditions });
  };

  const removeCondition = (index, tmoId) => {
    const tmoConditions = matchingRule.conditions[tmoId];
    tmoConditions.splice(index, 1);
    const conditions = { ...matchingRule.conditions, [tmoId]: tmoConditions };
    updateDraftMatchingRule({ ...matchingRule, conditions: conditions });
  };

  const updateTmoAggregatorMap = (tmoId, tmoAggId) => {
    let tmoAggMap = matchingRule.tmoAggregatorIdMap !== undefined ? matchingRule.tmoAggregatorIdMap : {};
    tmoAggMap[tmoId] = tmoAggId;
    updateDraftMatchingRule({ ...matchingRule, tmoAggregatorIdMap: tmoAggMap });
  };

  const newConditionAction = () => {
    return { isNew: true, conditions: [], actions: [] };
  };

  const updateConditionsActions = (conditionAction, index) => {
    const conditionsActions = matchingRule.conditionsActions ? matchingRule.conditionsActions : [];
    conditionsActions.splice(index, 1, conditionAction);
    updateDraftMatchingRule({ ...matchingRule, conditionsActions: conditionsActions });
  };

  return (
    <>
      <FullScreenView>
        <div style={{ padding: "16px" }}>
          <ModalHeader
            onCloseClick={() => {
              close();
            }}
            title={matchingRule.name}
            contentRight={
              <>
                <Button
                  disabled={matchingRule.locked}
                  onClick={() => {
                    close();
                  }}
                >
                  <Text>Cancel</Text>
                </Button>
                <Button
                  disabled={matchingRule.locked}
                  color="tertiary"
                  onClick={() => {
                    saveMatchingRule();
                  }}
                >
                  <Text>Save and exit</Text>
                </Button>
              </>
            }
          />
        </div>
        <div style={{ background: "#F7F8F9", paddingTop: "20px", marginTop: "20px" }}>
          <R>
            <C10>
              <ManageLayout
                headerTabs={
                  <TabsLayout
                    tabs={[
                      {
                        text: "Rule Builder",
                        onClick: () => {},
                      },
                    ]}
                    activeTab={0}
                  />
                }
                rightToolbar={
                  <Button
                    onClick={() => {
                      updateConditionsActions(newConditionAction(), matchingRule.conditionsActions ? matchingRule.conditionsActions.length : 0);
                    }}
                    disabled={matchingRule.locked}
                  >
                    <Text>+ Add Condition/ Action</Text>
                  </Button>
                }
              >
                <div
                  style={{
                    padding: "16px",
                    marginTop: "16px",
                    background: "#F3F3F5",
                    border: highlighted === 0 ? "2px solid #0070F2" : "1px solid #E6E8EB",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setHighlighted(0);
                  }}
                >
                  <R props="mb-32">
                    <Text size="md">Filter</Text>
                  </R>
                  <div style={{ borderBottom: "1px solid #E6E8EB" }}>
                    <R props="mb-16 mt-16">
                      <C4>
                        <Text size="sm">What data do you want to run this rule on?</Text>
                      </C4>
                      <C4>
                        <Dropdown>
                          <DropdownButton warning={""} disabled={matchingRule.locked} size="sm">
                            {matchingRule.tmoId === null
                              ? "any data is available from both sides"
                              : matchingRule.tmoId !== undefined
                              ? `any ${tmos.find((tmo) => tmo.id === matchingRule.tmoId)["name"]} data is available`
                              : "Please select an option"}
                            <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                          </DropdownButton>

                          <DropdownList>
                            {[...tmos, { name: "", id: null }].map((tmo, index) => {
                              return (
                                <DropdownListItem
                                  key={Math.random()}
                                  onClick={() => {
                                    selectTmo(tmo.id);
                                  }}
                                >
                                  <DropdownItem active={false} index={index}>
                                    <ItemBody>{`any ${tmo.name} data is available ${tmo.id === null ? "from both sides" : ""}`}</ItemBody>
                                  </DropdownItem>
                                </DropdownListItem>
                              );
                            })}
                          </DropdownList>
                        </Dropdown>
                      </C4>
                      <C4 props="text-right">
                        <InfoToolTip text={"Select which incoming data this rule should apply to"} />
                      </C4>
                    </R>
                  </div>
                  {tmos.map((tmo) => {
                    return [null, tmo.id].includes(matchingRule.tmoId) ? (
                      <R props="mt-32 mb-32 pb-16">
                        <R props="pr-0">
                          <C4>
                            <Text variant="muted" size="sm" weight="regular">
                              {`${tmo.name} filters`}
                            </Text>
                          </C4>
                          <C4></C4>
                          <C4 props="text-right">
                            <InfoToolTip text={"Further filter the incoming data so that the rule runs on a subset of the original data"} />
                          </C4>
                        </R>
                        {!matchingRule.conditions || !matchingRule.conditions[tmo.id]
                          ? ""
                          : matchingRule.conditions[tmo.id].map((condition, index) => {
                              return (
                                <div className="row mt-16 mb-16" style={{ alignItems: "top" }}>
                                  <div className="col-1">
                                    {index === 0 ? (
                                      <Text size="sm">When</Text>
                                    ) : (
                                      <span className="ml-32">
                                        <Text size="sm">and</Text>
                                      </span>
                                    )}
                                  </div>
                                  {condition.isNew ? (
                                    <FunctionSelector
                                      index={index}
                                      updateCondition={(updatedCondition, index) => updateCondition(updatedCondition, index, tmo.id)}
                                      removeCondition={() => {
                                        removeCondition(index, tmo.id);
                                      }}
                                      sortedFunctions={comparatorFunctionStore.getSortedFunctions()}
                                      disabled={matchingRule.locked}
                                    />
                                  ) : (
                                    <>
                                      <FunctionEditor
                                        condition={condition}
                                        updateCondition={(updatedCondition) => updateCondition(updatedCondition, index, tmo.id)}
                                        removeCondition={() => removeCondition(index, tmo.id)}
                                        manualInput={true}
                                        dropdownData={{
                                          [tmo.name]: tmoColumns[tmo.id] ? tmoColumns[tmo.id] : [],
                                        }}
                                        tags={recTypeStore.getRecTypeTags().filter((it) => it.noteType === NOTE_TYPE.VERIFICATION)}
                                        disabled={matchingRule.locked}
                                      />
                                    </>
                                  )}
                                </div>
                              );
                            })}
                        <R props="mt-16">
                          {matchingRule.locked ? (
                            ""
                          ) : (
                            <C4>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  addCondition(tmo.id);
                                }}
                              >
                                <Text size="sm" variant="link" weight="bold">{`+ Add ${tmo.name.toLowerCase()} filter`}</Text>
                              </span>
                            </C4>
                          )}
                        </R>
                      </R>
                    ) : (
                      ""
                    );
                  })}
                </div>

                <div
                  style={{
                    padding: "16px",
                    marginTop: "16px",
                    background: "#F3F3F5",
                    border: highlighted === 1 ? "2px solid #0070F2" : "1px solid #E6E8EB",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setHighlighted(1);
                  }}
                >
                  <R props="mb-32">
                    <Text size="md">Group/ Flatten</Text>
                  </R>

                  <R props="mt-32 mb-32">
                    <Groupings />

                    <div className="pr-0" style={{ borderTop: "1px solid #E6E8EB" }}>
                      <R props="mt-32 pr-0">
                        <C2>
                          <Text size="sm">Use these flattening rules</Text>
                        </C2>
                        {tmos.map((tmo) => {
                          const aggregators = tmoAggregators.filter((tmoAggregator) => tmoAggregator.tmoId === tmo.id);
                          const disabled = aggregators.length === 1;
                          const selectedAggregator =
                            matchingRule.tmoAggregatorIdMap && matchingRule.tmoAggregatorIdMap[tmo.id]
                              ? tmoAggregators.find((tmoAggregator) => tmoAggregator.id === matchingRule.tmoAggregatorIdMap[tmo.id])
                              : null;
                          if (aggregators.length === 1 && !selectedAggregator) {
                            //Autoselect the aggregator if only one exists
                            updateTmoAggregatorMap(tmo.id, aggregators[0].id);
                          }
                          return (
                            <C3>
                              <Dropdown>
                                <DropdownButton warning={""} disabled={disabled || matchingRule.locked} size="sm">
                                  {selectedAggregator ? selectedAggregator.name : `Please select a ${tmo.name} option`}
                                  <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
                                </DropdownButton>

                                <DropdownList>
                                  {tmoAggregators
                                    .filter((tmoAggregator) => tmoAggregator.tmoId === tmo.id)
                                    .map((tmoAggregator, index) => {
                                      return (
                                        <DropdownListItem
                                          key={Math.random()}
                                          onClick={() => {
                                            updateTmoAggregatorMap(tmo.id, tmoAggregator.id);
                                          }}
                                        >
                                          <DropdownItem active={false} index={index}>
                                            <ItemBody>{tmoAggregator.name}</ItemBody>
                                          </DropdownItem>
                                        </DropdownListItem>
                                      );
                                    })}
                                </DropdownList>
                              </Dropdown>
                            </C3>
                          );
                        })}
                        <C4 props="text-right">
                          <InfoToolTip
                            text={
                              "Flatten each group of rows into one single row using the selected flatten rules. The flatten rules determine how the data should be treated when flattened"
                            }
                          />
                        </C4>
                      </R>
                    </div>
                  </R>
                </div>
                {!matchingRule.conditionsActions
                  ? ""
                  : matchingRule.conditionsActions.map((conditionAction, index) => {
                      return (
                        <div
                          style={{
                            padding: "16px",
                            marginTop: "16px",
                            background: "#F3F3F5",
                            border: highlighted === index + 2 ? "2px solid #0070F2" : "1px solid #E6E8EB",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            setHighlighted(index + 2);
                          }}
                        >
                          <ConditionAction
                            index={index}
                            conditions={conditionAction.conditions}
                            conditionOptions={comparatorFunctionStore.getSortedFunctions()}
                            setConditions={(conditions) => {
                              updateConditionsActions({ ...conditionAction, conditions: conditions }, index);
                            }}
                            canAddConditions={true}
                            actions={conditionAction.actions}
                            actionOptions={{
                              "Tag as": recTypeStore.getRecTypeTags().filter((it) => [TAG].includes(it.noteType)),
                              "Assign to": recTypeStore.getRecTypeTags().filter((it) => it.noteType === CATEGORY),
                            }}
                            canAddActions={conditionAction.actions && conditionAction.actions.length < 1 ? true : false}
                            setActions={(actions) => {
                              updateConditionsActions({ ...conditionAction, actions: actions }, index);
                            }}
                            dropdownData={{ Results: groupTmoColumns }}
                            manualInput={true}
                            notes={recTypeStore.getRecTypeTags().filter((it) => [VERIFICATION, TAG].includes(it.noteType))}
                            disabled={matchingRule.locked}
                            removeConditionAction={() => removeConditionAction(index)}
                          />
                        </div>
                      );
                    })}
                <R props="mt-24">
                  <C4></C4>
                  <C4>
                    <R props="mt-16 mb-16">
                      <Button
                        disabled={matchingRule.locked}
                        onClick={() => {
                          updateConditionsActions(newConditionAction(), matchingRule.conditionsActions ? matchingRule.conditionsActions.length : 0);
                        }}
                      >
                        <Text>+ Add Condition/ Action</Text>
                      </Button>
                    </R>
                    <R>
                      <Text size="sm" weight="regular">
                        Add a condition to define the criteria for automating an action on the filtered data. Rules can add tags to rows or assign rows to
                        predefined categories (such as Match, Break etc). Any number of conditions/actions can be added to an individual rule.
                      </Text>
                    </R>
                  </C4>
                  <C4></C4>
                </R>
              </ManageLayout>
            </C10>
            <C2>
              <HelpSection>{highlighted < 2 ? <FilterAndGroupHelpText /> : <ConditionActionHelpText />}</HelpSection>
            </C2>
          </R>
        </div>
      </FullScreenView>
      <WarningModal
        title="Are you sure you want to close this rule without saving?"
        description={
          <Text size="sm" weight="regular">
            Any rule setup will be lost unless you select <b>“Save and exit”</b>. If you <b>“Close without saving”</b>, the rule will be created without any
            filters, conditions and actions populated. To keep the populated data select <b>“Cancel”</b> to return to the current rule setup and then select{" "}
            <b>“Save and exit”</b>
          </Text>
        }
        buttonClick={() => {
          closeModalAndResetFields();
        }}
        buttonText="Close without saving"
      />
    </>
  );
});

export { MatchingRuleBuilder };
