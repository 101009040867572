import { Text, Button, R, C4, C8, IconSaveAndSubmit, IconInformation } from "@fundrecs/ui-library";
import { Table } from "../ag-grid/Ag-grid";
import { Spinner } from "../layout/Spinner.js";
import { useStore } from "../../store/Store";
import { MATCH_PREVIEW_TYPE } from "../../utils/enums";
import { GlobalMappingEntriesPanel } from "./GlobalMappingEntriesPanel";

const MatchPreviewPanel = ({
  matchPreview,
  gridRef,
  matchPreviewRowData,
  closePreview,
  submitMatchedRowsDisabled,
  loading,
  headers,
  matchingRuleId,
  manualCategorizationOptions,
  setMatchingRulesRunning,
  matchSuccessUpdateTable,
  rec,
  globalMappingEntries,
}) => {
  const { uiStore, recStore, teamStore } = useStore();

  let successfulRowGroups = [];
  let failedRowGroups = [];
  if (matchPreviewRowData !== null) {
    matchPreviewRowData.forEach((rowGroup) => {
      if (rowGroup.criteriaList && rowGroup.criteriaList.length) {
        failedRowGroups.push(rowGroup);
      } else {
        successfulRowGroups.push(rowGroup);
      }
    });
  }

  const submitMatch = async () => {
    if (!gridRef || !gridRef.current || !gridRef.current.api || gridRef.current.api.getSelectedRows().length < 1) {
      uiStore.addNotification("error", "To run the rule, please select at least 1 row");
      return;
    }

    if (successfulRowGroups.length && matchPreview) {
      setMatchingRulesRunning(true);
      let response = { status: null };
      if (matchPreview === MATCH_PREVIEW_TYPE.SELECTED_MATCHING_RULE) {
        const rowIds = gridRef.current.api.getSelectedRows().map((row) => row.id);
        let requestData = { toBePersisted: true, rowIdSet: rowIds };
        response = await recStore.runMatchingRule(teamStore.getSelectedTeam().id, rec.id, rec.version, matchingRuleId, requestData);
      } else if (matchPreview === MATCH_PREVIEW_TYPE.MANUAL_CATEGORIZATION && Object.keys(manualCategorizationOptions)) {
        manualCategorizationOptions.rowIdSet = gridRef.current.api.getSelectedRows().map((it) => it.id);
        response = await recStore.manuallyCreateRowGroup(teamStore.getSelectedTeam().id, rec.id, rec.version, {
          ...manualCategorizationOptions,
          toBePersisted: true,
        });
      }
      if (response.status === 200) {
        matchSuccessUpdateTable(response.data.recVersion);
      }
      setMatchingRulesRunning(false);
    }
  };

  return matchPreview && gridRef && gridRef.current && gridRef.current.api && gridRef.current.api.getSelectedRows().length >= 1 ? (
    <>
      {failedRowGroups.length ? (
        <div style={{ background: "#FDE7EA", borderRadius: "4px" }} className="mt-16 pl-16 pt-16 pr-16 pb-16">
          <R>
            <C8>
              <IconInformation className="btn-sm-svg error" />
              <span className="pl-16"></span>
              <Text size="sm" variant="error">
                Some matches failed on minimum criteria
              </Text>
            </C8>
          </R>
          <R>
            <C8>
              <span className="pl-32"></span>
              <Text weight="regular" variant="error" size="sm">
                Rows that don’t meet criteria cannot be submitted
              </Text>
            </C8>
          </R>
        </div>
      ) : (
        ""
      )}

      <R props="pt-32 pl-0">
        <C8 props="pl-0">
          {successfulRowGroups.length ? (
            <>
              <Text variant="h6">Preview match</Text>
              <span className="ml-16"></span>
              <span className="text-left text-sm text-regular light-text-tertiary">
                Select <b>Submit</b> to save this match
              </span>
            </>
          ) : (
            ""
          )}
        </C8>

        <C4 props="text-right pb-8">
          <Button
            size="md"
            onClick={() => {
              closePreview();
            }}
            disabled={false}
            color="tertiary"
          >
            <Text size="sm" weight="medium">
              Clear Preview
            </Text>
          </Button>
          <span className="pr-8"></span>
          <Button
            size="md"
            onClick={() => {
              submitMatch();
            }}
            disabled={submitMatchedRowsDisabled || successfulRowGroups.length === 0}
            color="success-primary"
          >
            <>
              {loading ? <Spinner color="white" backgroundColor="#1e5eff" /> : <IconSaveAndSubmit className={`btn-md-svg`} />}
              <Text size="sm" weight="medium">
                Submit
              </Text>
            </>
          </Button>
        </C4>
      </R>
      {successfulRowGroups.length ? (
        <Table
          columnDefs={successfulRowGroups.length ? headers : []}
          rowData={matchPreviewRowData === null ? null : successfulRowGroups}
          height={`${17 + (successfulRowGroups.length === 1 ? 1 : successfulRowGroups.length - 1) * 8}vh`}
        />
      ) : (
        ""
      )}
      {failedRowGroups.length ? (
        <>
          <R props="pt-16 pl-0 pb-16">
            <C8 props="pl-0">
              <Text variant="error">Failed minimum criteria: </Text>
              <span className="ml-16"></span>
              <span className="text-left text-sm text-regular light-text-tertiary">{failedRowGroups.length === 1 ? failedRowGroups[0]["Type"] : ""}</span>
            </C8>
          </R>
          <Table
            columnDefs={failedRowGroups.length ? headers : []}
            rowData={matchPreviewRowData === null ? null : failedRowGroups}
            height={`${17 + (failedRowGroups.length === 1 ? 1 : failedRowGroups.length - 1) * 8}vh`}
          />
        </>
      ) : (
        ""
      )}

      <div className="pt-16"></div>
      <GlobalMappingEntriesPanel globalMappingEntries={globalMappingEntries} />
    </>
  ) : (
    ""
  );
};

export { MatchPreviewPanel };
