import { observer } from "mobx-react-lite";
import { useState } from "react";
import { R, Text, Toggle, C8, C2, IconDownArrow, IconUpArrow, OneProductGlobalMappingsIcon, IconSuccessMessage } from "@fundrecs/ui-library";
import { isUserAuthorized } from "../../AuthorizationWrapper";
import { useStore } from "../../../store/Store";
import { AUTHORITIES } from "../../../utils/enums";
import { ifNullUndefinedArray } from "../../../utils/utils";
import { ReplaceFileAccountSetup } from "../ReplaceFileAccountSetup";
import { GlobalMappingAccountSetup } from "../../globalMapping/GlobalMappingAccountSetup";
import { AutoCompleteAccountSetup } from "../../autoComplete/AutoCompleteAccountSetup";
import { ReactComponent as IconCalendar } from "../../../icons/_calendar-theme-quartz.svg";

const EnabledRecTypes = observer(({ teamId, account }) => {
  const { recTypeStore, accountStore, rolesStore } = useStore();
  const [enabledRecTypes, setEnabledRecTypes] = useState([]);
  const recTypesForTeam = ifNullUndefinedArray(recTypeStore.getRecTypes());
  const [initialised, setInitialised] = useState(false);

  const updateEnabledRecTypes = (recType, enabled) => {
    //If recType has just been enabled
    const recTypeEnabled = enabledRecTypes.find((it) => it.id === recType.id);
    if (!recTypeEnabled && enabled) {
      setEnabledRecTypes([...enabledRecTypes, recType]);
    } else if (recTypeEnabled && !enabled) {
      setEnabledRecTypes(enabledRecTypes.filter((it) => it.id !== recType.id));
    }
  };

  const refreshRows = async () => {
    if (account) {
      const enabledRecTypes = await accountStore.fetchEnabledRecTypesForAccount(teamId, account.id);
      if (enabledRecTypes.length) {
        setEnabledRecTypes(enabledRecTypes);
      }
    }
  };

  if (recTypesForTeam.length && account && !initialised) {
    refreshRows();
    setInitialised(true);
  }

  const disabledRecTypes = recTypesForTeam.filter((recType) => {
    return enabledRecTypes.find((it) => it.id === recType.id) === undefined;
  });

  return (
    <>
      <div className="pt-32"></div>
      <Text size="lg">Reconciliation Types</Text>
      <Text weight="regular" size="sm" variant="secondary">
        Select the types of reconciliation you want to perform on this fund. Reconciliation types must be enabled before a reconciliation can take place.
      </Text>
      <div className="pt-32 pb-32">
        <Text weight="normal">
          <b>Enabled</b> {`${enabledRecTypes.length}`}
        </Text>
        {enabledRecTypes.map((recType) => {
          return <RecTypePanel teamId={teamId} account={account} recType={recType} enabled={true} updateEnabledRecTypes={updateEnabledRecTypes} />;
        })}
      </div>
      <div className="ml-16" style={{ borderTop: "1px solid #E6E8EB" }}></div>
      <div className="pt-32 pb-32">
        <Text weight="normal">Available {`${disabledRecTypes.length}`}</Text>
        {disabledRecTypes.map((recType) => {
          return <RecTypePanel teamId={teamId} account={account} recType={recType} enabled={false} updateEnabledRecTypes={updateEnabledRecTypes} />;
        })}
      </div>
    </>
  );
});

const RecTypePanel = ({ teamId, account, recType, enabled, updateEnabledRecTypes }) => {
  const { rolesStore, recTypeStore, accountStore } = useStore();
  const WORKING_WEEK = "Working week";
  const AUTO_COMPLETE = "Auto-complete";
  const REPLACE_FILES = "Replace files";
  const GLOBAL_MAPPINGS = "Global mappings";

  const settings = [AUTO_COMPLETE, REPLACE_FILES, GLOBAL_MAPPINGS];
  const settingsIcon = {
    [WORKING_WEEK]: <IconCalendar className="btn-lg-svg" />,
    [AUTO_COMPLETE]: <IconSuccessMessage className="btn-xs-svg" />,
    [REPLACE_FILES]: <OneProductGlobalMappingsIcon className="btn-xs-svg" currentcolor="green" />,
    [GLOBAL_MAPPINGS]: <OneProductGlobalMappingsIcon className="btn-xs-svg" />,
  };
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [settingsView, setSettingsView] = useState(settings[0]);

  const handleResponse = (response, recType, enabled) => {
    if (response.status === 200) {
      updateEnabledRecTypes({ ...recType, version: response.data.recTypeVersion }, enabled);
      recTypeStore.updateVersion(recType.id, response.data.recTypeVersion);
      accountStore.updateSelectedAccountVersion(response.data.accountVersion);
    }
  };

  return (
    <div className="mt-8 pl-24 pt-24 pb-24 pr-24 " style={{ background: "#fff", borderRadius: "12px", border: "1px solid #E6E8EB" }}>
      <R>
        <C8 props="pl-0">
          <Text weight="medium" variant="secondary" element="div">
            {recType.name}
          </Text>
          <Text weight="regular" size="sm">
            {recType.description}
          </Text>
        </C8>

        <C2>
          <div className="d-flex">
            <Toggle
              disabled={!isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_REC_TYPE_ENABLE]) })}
              checked={enabled}
              onChange={async (event) => {
                const response = enabled
                  ? await recTypeStore.disableAccountForRecType(teamId, recType.id, account.id, recType.version, account.version)
                  : await recTypeStore.enableAccountForRecType(teamId, recType.id, account.id, recType.version, account.version);
                handleResponse(response, recType, !enabled);
              }}
            />
            <div className="ml-16">
              <Text size="sm" weight="bold">
                {enabled ? "Active" : "Inactive"}
              </Text>
            </div>
          </div>
        </C2>
        <C2 props="align-right">
          <div className="d-flex">
            <div className="ml-64">
              <Text size="sm" weight="bold">
                Settings
              </Text>
            </div>
            <div
              className="ml-16"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSettingsOpen(!settingsOpen);
              }}
            >
              {settingsOpen ? <IconUpArrow className="btn-lg-svg dropdown-active-icon" /> : <IconDownArrow className="btn-lg-svg dropdown-active-icon" />}
            </div>
          </div>
        </C2>
      </R>
      {settingsOpen ? (
        <div className="d-flex">
          <div className="mt-32 pt-24 pr-24" style={{ width: "20%", borderTop: "1px solid #E6E8EB", borderRight: "1px solid #E6E8EB" }}>
            <div>
              <div className="d-flex justify-content-between pt-8 pb-8 pl-8 pr-8" style={{ cursor: "pointer" }} onClick={() => {}}>
                <Text variant="secondary" size="sm" weight="bold">
                  Settings
                </Text>
              </div>
              {settings.map((setting, index) => {
                return (
                  <div
                    className="pt-8 pb-8 pl-8 pr-8"
                    onClick={() => {
                      setSettingsView(setting);
                    }}
                    style={{
                      cursor: "pointer",
                      background: settingsView === setting ? "#F7F8F9" : "white",
                      margin: "2px",
                      borderRadius: "8px",
                    }}
                  >
                    {/*settingsIcon[setting]*/}
                    <span className="ml-8"></span>
                    <Text size="sm">{setting}</Text>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-32 pt-24 pl-24 pr-24" style={{ width: "80%", borderTop: "1px solid #E6E8EB" }}>
            {settingsView === WORKING_WEEK ? (
              ""
            ) : settingsView === AUTO_COMPLETE ? (
              <AutoCompleteAccountSetup
                recTypeId={recType.id}
                recTypeAutocompleteEnabled={recType.recTypeAutocompleteEnabled ? recType.recTypeAutocompleteEnabled : false}
                autoCompleteProp={recType.autoComplete ? recType.autoComplete : null}
                autoCompleteSchedulerProp={recType.autoCompleteScheduler ? recType.autoCompleteScheduler : null}
                recStartDateProp={recType.recStartDate}
                accountId={account ? account.id : null}
                accountVersionProp={account ? account.version : null}
              />
            ) : settingsView === REPLACE_FILES ? (
              <ReplaceFileAccountSetup recTypeId={recType.id} accountId={account ? account.id : null} accountVersion={account ? account.version : null} />
            ) : settingsView === GLOBAL_MAPPINGS ? (
              <GlobalMappingAccountSetup recTypeId={recType.id} accountId={account ? account.id : null} accountVersion={account ? account.version : null} />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export { EnabledRecTypes };
