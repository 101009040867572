import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Text, LinkText, R, C, C10, IconDownArrow, IconRightArrow } from "@fundrecs/ui-library";
import { Table } from "../ag-grid/Ag-grid";
import { Badge } from "../layout/Layout";

const RowCountsTable = observer(({ updateUrl = null, allSubAccounts = [], rowCounts = [], rowTableTabs = [] }) => {
  const gridRef = useRef(null);
  const [show, setShow] = useState(false);
  const unmatchedText = "Unprocessed";
  const processedText = "Processed";
  const linkDisabled = updateUrl === null;
  const rows = allSubAccounts.map((subAccount) => {
    const counts = rowCounts.find((it) => it.id === subAccount.id);
    return {
      ...subAccount,
      unprocessedCount: counts ? counts.unprocessedRowsCount : 0,
      processedCount: counts ? counts.processedRowsCount : 0,
    };
  });

  const cols = [
    { headerName: "Sub account", field: "name" },
    {
      field: "",
      headerName: "Unprocessed",
      cellRenderer: (params) => {
        const tab = rowTableTabs.find((it) => it.text === unmatchedText);
        return createLink(params.data.unprocessedCount, params.data, tab, "blue");
      },
    },
    {
      field: "processed",
      headerName: "Processed",
      cellRenderer: (params) => {
        const tab = rowTableTabs.find((it) => it.text === processedText);
        return createLink(params.data.processedCount, params.data, tab, "green");
      },
    },
  ];

  const createLink = (text, subAccount, note, colour) => {
    let style = { fontWeight: "700", cursor: "pointer", backgroundColor: "lightgray", color: "black", width: `${text.length * 15}px` };
    if (colour === "green") {
      style.backgroundColor = "#E8F6EE"; //green
      style.color = "#15A251";
    } else if (colour === "blue") {
      style.backgroundColor = "#E9EFFF"; //blue
      style.color = "#1E5EFF";
    }

    return (
      <span
        onClick={() => {
          if (!linkDisabled) {
            updateUrl(subAccount, note);
          }
        }}
      >
        <Badge text={text} style={style} />
      </span>
    );
  };
  return (
    <>
      <R props="mb-16 mt-16 ">
        <C10 props="ml-32">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShow(!show);
            }}
          >
            <LinkText>
              <Text sixe="sm" weight="regular">
                {show ? <IconDownArrow className="btn-lg-svg" /> : <IconRightArrow className="btn-lg-svg" />}
              </Text>
            </LinkText>
          </span>
          <Text weight="bold" size="md">
            {`View all sub accounts (${rows.length})`}
          </Text>
        </C10>
      </R>
      {show ? (
        <R props="mt-32">
          <C props="ml-32">
            <Table columnDefs={cols} rowData={rows} ref={gridRef} height={"25vh"} sizeToFit={true} />{" "}
          </C>
        </R>
      ) : (
        ""
      )}

      <span className="pb-16"></span>
    </>
  );
});

export { RowCountsTable };
