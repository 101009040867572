import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Text, C2, C8, Dropdown, DropdownButton, DropdownItem, DropdownList, DropdownListItem, IconDownArrow, ItemBody } from "@fundrecs/ui-library";
import { EditableText } from "../../editable/EditableText";
import { useStore } from "../../../store/Store";
import { isUserAuthorized } from "../../AuthorizationWrapper";
import { AUTHORITIES } from "../../../utils/enums";

const AccountDetails = observer(() => {
  const { accountStore, teamStore, currencyStore, clientStore, rolesStore } = useStore();
  const account = accountStore.getSelectedAccount();
  const team = teamStore.getSelectedTeam();
  const clients = clientStore.getAllClients();
  const currencies = currencyStore.getCurrencies();
  const workingWeekSetting = account && account.settings && account.settings.length ? account.settings.find((setting) => setting.type === "workingweek") : [];
  const [initialised, setInitialised] = useState(false);

  if (team && team.id && !initialised) {
    currencyStore.fetchAllCurrencies(team.id);
    setInitialised(true);
  }
  const cardStyle = {
    padding: "8px",
    marginBottom: "16px",
    border: "1px solid #CDD0D7",
    borderRadius: "6px",
    background: "#fff",
  };

  const rowStyle = {
    display: "flex",
    marginLeft: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
  };

  const rowStyleWithBorder = { ...rowStyle, borderBottom: "1px solid #E6E7EB" };

  const editAccount = (key, value) => {
    let body = { name: account.name, identifier: account.identifier };
    body[key] = value;
    accountStore.updateAccount(team.id, account.clientId, account.id, account.version, body);
  };

  const setClient = async (client) => {
    const response = await accountStore.setClient(team.id, client.id, account.id, account.version);
    if (response.status === 200) {
      accountStore.setSelectedAccount({ ...account, clientName: client.name, version: response.data });
    }
  };

  const changeCurrency = async (currency) => {
    const response = await accountStore.updateCurrency(team.id, account.clientId, account.id, currency.id, account.version);
    if (response.status === 200) {
      accountStore.setSelectedAccount({ ...account, currency: currency, version: response.data });
    }
  };

  const updateSetting = async (setting) => {
    const response = await accountStore.updateSetting(team.id, account.clientId, account.id, account.version, setting);
  };

  const DetailsPanel = ({ style, title, details }) => {
    return (
      <div style={style}>
        <C2>
          <Text size="sm" weight="regular">
            {title}
          </Text>
        </C2>
        <C8>{details}</C8>
        <C2></C2>
      </div>
    );
  };

  const editingDisabled = !isUserAuthorized({ teamId: team.id, allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) });

  return (
    <>
      <div className="pt-32"></div>
      <Text size="lg">Fund Details</Text>
      <div className="pt-16"></div>
      <div style={cardStyle}>
        <DetailsPanel
          style={rowStyleWithBorder}
          title="Name"
          details={
            <EditableText
              name="name"
              label=""
              value={account ? account.name : ""}
              onSave={(value) => {
                editAccount("name", value);
              }}
              warningText=""
              disabled={editingDisabled}
            />
          }
        />
        <DetailsPanel
          style={rowStyleWithBorder}
          title="Fund Identifier"
          details={
            <EditableText
              name="name"
              label=""
              value={account ? account.identifier : ""}
              onSave={(value) => {
                editAccount("identifier", value);
              }}
              warningText=""
              disabled={editingDisabled}
            />
          }
        />
        <DetailsPanel
          style={rowStyleWithBorder}
          title="Client"
          details={
            <Dropdown spacers={["mt-8", "mr-12"]}>
              <DropdownButton size="sm">
                {account && account.clientName ? account.clientName : "Select a client"}
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </DropdownButton>

              <DropdownList>
                {clients.map((client, index) => {
                  return (
                    <DropdownListItem
                      key={Math.random()}
                      onClick={() => {
                        setClient(client);
                      }}
                    >
                      <DropdownItem active={false} index={index}>
                        <ItemBody>{client.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          }
        />
        <DetailsPanel
          style={rowStyleWithBorder}
          title="Fund Currency"
          details={
            <Dropdown spacers={["mr-12"]}>
              <DropdownButton disabled={editingDisabled} size="sm">
                {account && account.currency ? `${account.currency.code} - ${account.currency.description}` : "Select a currency"}
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </DropdownButton>

              <DropdownList>
                {currencies.map((currency, index) => {
                  return (
                    <DropdownListItem
                      key={Math.random()}
                      onClick={() => {
                        changeCurrency(currency);
                      }}
                    >
                      <DropdownItem active={false} index={index}>
                        <ItemBody>{`${currency.code} - ${currency.description}`}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          }
        />
        <DetailsPanel
          style={rowStyleWithBorder}
          title="Business Week"
          details={
            <Dropdown spacers={["mt-8", "mr-12"]}>
              <DropdownButton disabled={editingDisabled} size="sm">
                {workingWeekSetting && workingWeekSetting.dropDownList ? workingWeekSetting.dropDownList[workingWeekSetting.value] : "Select a business week"}
                <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
              </DropdownButton>
              <ul
                class="dropdown-menu dropdown-menu-end box w-100 dropdown-item-text-undefined"
                aria-labelledby="dropdownMenuLink"
                style={{ maxHeight: "300px", background: "#fff", overflowX: "scroll" }}
              >
                {workingWeekSetting &&
                  workingWeekSetting.dropDownList &&
                  Object.entries(workingWeekSetting.dropDownList).map((valueName, index) => {
                    return (
                      <DropdownListItem
                        key={Math.random()}
                        onClick={() => {
                          updateSetting({ ...workingWeekSetting, value: valueName[0] });
                        }}
                      >
                        <DropdownItem active={false} index={index}>
                          <ItemBody>{valueName[1]}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
              </ul>
            </Dropdown>
          }
        />
        <DetailsPanel
          style={rowStyle}
          title="Created By"
          details={
            <Text size="md" weight="regular">
              {account && account.createdBy ? account.createdBy.userName : ""}
            </Text>
          }
        />
      </div>
    </>
  );
});

export { AccountDetails };
