import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import {
  Text,
  Button,
  IconAdd,
  IconAlertMessage,
  modalInstance,
  R,
  C2,
  C6,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownListItem,
  ItemBody,
  IconCheck,
  Toggle,
  VerticalMenuDropdown,
  VerticalMenuListItem,
} from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { AuthWrapper } from "../../AuthorizationWrapper";
import { WarningModal } from "../../WarningModal";
import { AUTHORITIES, MODALS } from "../../../utils/enums";
import { CreateCarryForwardRuleModal } from "./CreateCarryForwardRuleModal";

const CarryForwardSetup = observer(({ title, description, modalId, manualActions = false, carryForwardConfig }) => {
  const { rolesStore, carryForwardStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recType");
  let rules = carryForwardConfig && carryForwardConfig.carryGroups ? carryForwardConfig.carryGroups : [];
  const [selectedRule, setSelectedRule] = useState(null);

  const viewRule = (rule) => {
    setSelectedRule(rule);
    modalInstance(modalId).show();
  };

  const deleteRule = async (rule) => {
    const resp = manualActions
      ? await carryForwardStore.deleteManualActionCarryForwardRule(teamId, rule.id, rule.version)
      : await carryForwardStore.deleteRowGroupCarryForwardRule(teamId, rule.id, rule.version);
    if (resp.status === 200) {
      rules = [];
      carryForwardStore.fetchCarryForwardRules(teamId, recTypeId);
    }
  };

  return (
    <>
      <div>
        <CarryForwardTrigger type={title} carryForwardConfig={carryForwardConfig} teamId={teamId} recTypeId={recTypeId} manualActions={manualActions} />
      </div>
      <div className="d-flex justify-content-between mt-16 mb-16">
        <div>
          <Text variant="muted" weight="bold">{`RULES ${rules.length}`}</Text>
        </div>
        <AuthWrapper
          teamId={teamId}
          oneRequired={rolesStore.getActions([AUTHORITIES.RECS_CARRY_MANUAL_GROUP_CREATE, AUTHORITIES.RECS_CARRY_AUTO_GROUP_CREATE])}
        >
          <Button
            size="md"
            onClick={() => {
              modalInstance(modalId).show();
            }}
            color="primary-secondary"
          >
            <IconAdd className="btn-sm-svg" />
            <Text size="sm" weight="bold">
              New rule
            </Text>
          </Button>
        </AuthWrapper>
      </div>
      <CreateCarryForwardRuleModal
        title={title}
        description={description}
        modalId={modalId}
        manualActions={manualActions}
        setRuleToView={setSelectedRule}
        ruleToView={selectedRule}
      />
      {rules.length ? (
        <>
          {rules.map((rule, index) => {
            return (
              <div style={{ borderBottom: "1px solid #E6E8EB" }} key={index}>
                <R props="mt-16 mb-16">
                  <C2>
                    <Text size="sm" weight="bold">
                      {rule.name}
                    </Text>
                  </C2>
                  <C6>
                    <Text size="sm" weight="regular">
                      {rule.description}
                    </Text>
                  </C6>
                  <C2>
                    <VerticalMenuDropdown>
                      <VerticalMenuListItem
                        text="View"
                        onClick={() => {
                          viewRule(rule);
                        }}
                      />
                      <VerticalMenuListItem
                        text="Delete"
                        onClick={() => {
                          deleteRule(rule);
                        }}
                      />
                    </VerticalMenuDropdown>
                  </C2>
                </R>
              </div>
            );
          })}
        </>
      ) : (
        <div className="pt-16 pb-16 pl-16 pr-16" style={{ backgroundColor: "#FFF8F1", borderRadius: "4px" }}>
          <div>
            <span className="pr-8">
              <IconAlertMessage color="#D36133" />
            </span>
            <Text variant="warning">Requires at least one rule</Text>
          </div>
          <div className="pl-32">
            <Text variant="warning" weight="regular" size="sm">
              In order to activate carry forward manual actions for this reconciliation type you must create at least one rule.
            </Text>
            <div className="mt-16"></div>
          </div>
        </div>
      )}
    </>
  );
});

const CarryForwardTrigger = ({ type = "", carryForwardConfig = null, teamId, recTypeId, manualActions = false }) => {
  const { carryForwardStore } = useStore();
  const [triggerConfig, setTriggerConfig] = useState({ enabled: false, triggers: [] });
  const [initialised, setInitialised] = useState(false);

  if (!initialised && carryForwardConfig) {
    setInitialised(true);
    setTriggerConfig({ enabled: carryForwardConfig.enabled, triggers: carryForwardConfig.triggers });
  }
  const FILE_UPLOAD = "FILE_UPLOAD";
  const RUN_RULES = "RUN_RULES";
  const triggerOptions = [
    { option: FILE_UPLOAD, text: "On file upload" },
    { option: RUN_RULES, text: "When rules are manually run" },
  ];

  //Create a modal id based on type to specify the warning modal for different carry forward types
  const modalId = `${MODALS.WARNING}${type.replace(/\s/g, "")}`;

  const enableRules = async (enabled, triggers) => {
    const body = { recTypeId: recTypeId, enable: enabled, triggers: triggers };

    const resp = manualActions
      ? await carryForwardStore.enableManualActionCarryForwardRule(teamId, body)
      : await carryForwardStore.enableRowGroupCarryForwardRule(teamId, body);
    if (resp.status === 200) {
      setTriggerConfig({ enabled: enabled, triggers: triggers });
    }
  };

  const toggleTrigger = (trigger) => {
    if (triggerConfig.triggers.includes(trigger)) {
      return triggerConfig.triggers.filter((it) => it !== trigger);
    } else {
      return [...triggerConfig.triggers, trigger];
    }
  };

  const triggersText = () => {
    let text = "";
    triggerConfig.triggers.forEach((trigger, index) => {
      const triggerOption = triggerOptions.find((it) => it.option === trigger);
      if (triggerOption) {
        text = text + `${triggerOption.text}${index < triggerConfig.triggers.length - 1 ? ", " : ""}`;
      }
    });
    return text;
  };

  const noRulesExist = !carryForwardConfig || !carryForwardConfig.carryGroups || carryForwardConfig.carryGroups.length === 0;
  const triggersEnabled = !noRulesExist && triggerConfig.enabled;
  return (
    <div className="pb-16 pl-0">
      <WarningModal
        modalId={modalId}
        title={`Are you sure you want to disable ${type}?`}
        description={`This action will disable ${type} rules for this reconciliation type. The rules will not be deleted and this setting can be reactivated at any time.`}
        buttonClick={() => {
          enableRules(false, [FILE_UPLOAD, RUN_RULES]);
          modalInstance(modalId).hide();
        }}
        buttonText="Disable"
      />
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <Toggle
            disabled={noRulesExist}
            checked={triggersEnabled}
            onChange={() => {
              if (triggersEnabled) {
                modalInstance(modalId).show();
              } else {
                enableRules(true, [FILE_UPLOAD, RUN_RULES]);
              }
            }}
          />

          <div className="ml-16">
            <Text size="md" weight="regular">
              {`${type}:`}
            </Text>
            <span className="ml-8"></span>
            <Text weight="bold" size="sm" variant={triggersEnabled ? "success" : "error"}>
              {triggersEnabled ? "Active" : "Inactive"}
            </Text>
          </div>
        </div>
        {triggersEnabled ? (
          <div className="d-flex justify-content-start">
            <Text weight="bold" variant="secondary" size="sm" element="div">
              Run trigger
            </Text>

            <div style={{ width: "20rem" }}>
              <Dropdown>
                <DropdownButton size="sm">
                  <span>{triggersText()}</span>
                </DropdownButton>
                <ul className="dropdown-menu dropdown-sizing" aria-labelledby="dropdownMenuLink" style={{ background: "#fff", overflowX: "scroll" }}>
                  {triggerOptions.map((option, index) => {
                    return (
                      <DropdownListItem
                        onClick={() => {
                          enableRules(triggerConfig.enabled, toggleTrigger(option.option));
                        }}
                        key={index + 1}
                      >
                        <DropdownItem active={null} index={0}>
                          <ItemBody>{option.text}</ItemBody>
                          {triggerConfig.triggers.find((it) => it === option.option) ? <IconCheck className="btn-sm-svg" /> : ""}
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </ul>
              </Dropdown>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export { CarryForwardSetup };
