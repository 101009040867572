import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Button,
  Modal,
  ModalHeader,
  ModalText,
  ModalFooter,
  Input,
  modalInstance,
  Dropdown,
  DropdownButtonText,
  DropdownButton,
  ItemBody,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  R,
  C6,
  IconDownArrow,
  IconCheck,
  DatePicker,
} from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { MODALS } from "../../utils/enums";
import { stringifyDate } from "../../utils/dates";
import { PATH } from "../../utils/urls";
import { ifNullUndefinedArray } from "../../utils/utils";

// set max file size of 15 MB
const FILE_SIZE_MAX_MEGABYTES = 15;
const FILE_SIZE_MAX_BYTES = FILE_SIZE_MAX_MEGABYTES * 1024 * 1024;
const ALL_ACCOUNTS = "All funds";
const ALL_SUB_ACCOUNTS = "All sub accounts";

/**
 * Modal to allow a user to upload files manually, to be reused throught fusion-recs app
 */
const ManualFileUploadModal = observer(({ teamId, recType, selectedDate }) => {
  const { recTypeStore, meStore, uiStore, uploadedFileStore } = useStore();
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());

  const [tmos, setTmos] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);
  const [datePickerDate, setDatePickerDate] = useState(null);

  const [file, setFile] = useState();
  const [selectedRecType, setSelectedRecType] = useState(null);
  const [selectedTmo, setSelectedTmo] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedSubAccount, setSelectedSubAccount] = useState(null);
  const [workflowUuids, setWorkflowUuids] = useState([]);
  const [workflowsAndTemplates, setWorkflowsAndTemplates] = useState([]);

  const fetchRecTypeData = async (recType) => {
    setSelectedRecType(recType);
    setSelectedTmo(null);
    setSelectedWorkflow(null);
    setSelectedTemplate(null);
    setWorkflowUuids([]);
    setWorkflowsAndTemplates([]);
    const resp = await recTypeStore.fetchTmosForRecType(teamId, recType.id);
    const resp2 = await recTypeStore.fetchClientsAccountsPerRecType(teamId, recType.id);
    if (resp.status === 200) {
      setTmos(resp.data);
    }

    if (resp2.status === 200) {
      let accountsList = [];
      let subAccountsList = [];
      resp2.data.forEach((clientData) => {
        clientData.accountList.forEach((acc) => {
          if (!accountsList.filter((it) => it.id === acc.account.id).length) {
            accountsList.push(acc.account);
          }
          acc.subAccountList.forEach((subAcc) => {
            if (!subAccountsList.filter((it) => it.id === subAcc.id).length) {
              subAccountsList.push(subAcc);
            }
          });
        });
      });

      setAccounts([{ name: ALL_ACCOUNTS, id: null }, ...accountsList]);
      setSubAccounts([{ name: ALL_SUB_ACCOUNTS, id: null }, ...subAccountsList]);
    }
  };

  if (!selectedRecType && recType) {
    fetchRecTypeData(recType);
  }

  const fetchWorkflowsAndTemplates = async (teamId, tmoId) => {
    setSelectedWorkflow(null);
    setSelectedTemplate(null);
    setWorkflowUuids([]);
    setWorkflowsAndTemplates([]);
    let resp = await recTypeStore.getWorkflowsAndTemplatesForTmo(teamId, tmoId);
    if (resp.status === 200) {
      let groupedData = Object.groupBy(resp.data, ({ workflowUuid }) => workflowUuid);
      const workflowUuids = Object.keys(groupedData);
      setWorkflowsAndTemplates(resp.data);
      setWorkflowUuids(workflowUuids);
    }
  };

  const fileIsInvalid = file && file.size > FILE_SIZE_MAX_BYTES;
  const uploadEnabled = selectedRecType && selectedTmo && selectedWorkflow && selectedTemplate && file && !fileIsInvalid;

  const handleClose = () => {
    modalInstance(MODALS.MANUAL_UPLOAD).hide();
    setFile(null);
    setWorkflowUuids([]);
    setWorkflowsAndTemplates([]);
    setAccounts([]);
    setSubAccounts([]);
    setSelectedAccount(null);
    setDatePickerDate(null);
    setSelectedSubAccount(null);
    setSelectedWorkflow(null);
    setSelectedTemplate(null);
    fileInputRef.current.value = null;
  };

  const handleUpload = async () => {
    if (!file) {
      uiStore.addNotification("error", "Please select a file to upload");
      return;
    }
    if (!selectedRecType) {
      uiStore.addNotification("error", "Please select a rec type");
      return;
    }
    if (!selectedTmo) {
      uiStore.addNotification("error", "Please select a side to which to upload the file");
      return;
    }
    if (!selectedWorkflow) {
      uiStore.addNotification("error", "Please select a workflow");
      return;
    }
    if (!selectedTemplate) {
      uiStore.addNotification("error", "Please select a template");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("workFlow", selectedTemplate.workflowUuid);
    formData.append("template", selectedTemplate.templateUuid);
    formData.append("recTypeId", selectedRecType.id);
    formData.append("tmoId", selectedTmo.id);
    if (selectedAccount && selectedAccount.uuid) {
      formData.append("account", selectedAccount.uuid);
    }
    if (selectedSubAccount && selectedSubAccount.uuid) {
      formData.append("subAccount", selectedSubAccount.uuid);
    }
    if (datePickerDate) {
      formData.append("date", stringifyDate(datePickerDate, "-"));
    } else if (selectedDate) {
      formData.append("date", stringifyDate(selectedDate, "-"));
    }

    const resp = await uploadedFileStore.uploadFile(teamId, formData);
    if (resp.status === 200 && resp.data.id) {
      handleClose();
      navigate(`${PATH.UPLOADED_FILE}?teamId=${teamId}&fileId=${resp.data.id}`);
    }
  };

  return (
    <Modal modalId={MODALS.MANUAL_UPLOAD}>
      <div className="d-flex flex-column modal-body">
        <ModalHeader heading={`Upload a file (max. size ${FILE_SIZE_MAX_MEGABYTES} MB)`} />
        <ModalText text="Your workflow will start a run as soon as this file has been uploaded if its source is “file upload” and it has an automated run trigger." />
      </div>
      <div className="pt-16 pl-32 pr-32">
        <Text size="sm" weight="medium" variant="secondary">
          Select file to upload
        </Text>
        <div className="pt-8">
          <Input
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
            type="file"
            placeholderText="No file chosen"
            size="md"
            warningMessage={`File exceeds maximum upload size of ${FILE_SIZE_MAX_MEGABYTES} MB`}
            warning={fileIsInvalid}
            innerRef={fileInputRef}
            disabled={false}
          />
        </div>

        <DropdownPanel
          title="Select reconciliation type"
          options={recTypes}
          onClick={(recType) => {
            fetchRecTypeData(recType);
          }}
          selectedOption={selectedRecType}
        />
        <DropdownPanel
          title="Select which side of the data"
          options={tmos}
          onClick={(tmo) => {
            setSelectedTmo(tmo);
            fetchWorkflowsAndTemplates(teamId, tmo.id);
          }}
          selectedOption={selectedTmo}
        />
        <DropdownPanel
          title="Select workflow"
          options={
            !workflowUuids.length
              ? []
              : workflowUuids.map((uuid) => {
                  return workflowsAndTemplates.find((it) => it.workflowUuid === uuid);
                })
          }
          nameKey="workflowName"
          onClick={(workflow) => {
            setSelectedWorkflow(workflow);
            setSelectedTemplate(null);
          }}
          selectedOption={selectedWorkflow}
        />
        <DropdownPanel
          title="Select template to apply to file"
          options={!selectedWorkflow ? [] : workflowsAndTemplates.filter((it) => it.workflowUuid === selectedWorkflow.workflowUuid)}
          nameKey="templateName"
          onClick={(template) => {
            setSelectedTemplate(template);
          }}
          selectedOption={selectedTemplate}
        />
        <div className="pt-20">
          <Text size="sm" weight="medium" variant="secondary">
            Upload data for the following
          </Text>
          <div className="pt-8">
            <Dropdown>
              <DropdownButton size="sm" disabled={false}>
                <DropdownButtonText>
                  <ItemBody>
                    <span>{selectedAccount ? selectedAccount.name : ALL_ACCOUNTS}</span>
                  </ItemBody>
                  <IconDownArrow className="btn-sm-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList>
                {accounts.map((option, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        setSelectedAccount(option);
                      }}
                    >
                      <DropdownItem index={index}>
                        <ItemBody>{option.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>

          <div className="pt-8">
            <Dropdown>
              <DropdownButton size="sm" disabled={false}>
                <DropdownButtonText>
                  <ItemBody>
                    <span>{selectedSubAccount ? selectedSubAccount.name : ALL_SUB_ACCOUNTS}</span>
                  </ItemBody>
                  <IconDownArrow className="btn-sm-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList>
                {subAccounts.map((option, index) => {
                  return (
                    <DropdownListItem
                      onClick={() => {
                        setSelectedSubAccount(option);
                      }}
                    >
                      <DropdownItem index={index}>
                        <ItemBody>{option.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
        </div>
        <div className="pt-20 ">
          <Text size="sm" weight="medium" variant="secondary">
            Please select a date
          </Text>
          <div className="pt-8">
            <DatePicker
              date={datePickerDate ? datePickerDate : selectedDate}
              onDateChange={(date) => {
                setDatePickerDate(date);
              }}
              dateFormat={meStore.getUserDateFormat()}
            />
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button color="tertiary" onClick={handleClose} disabled={false}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button onClick={handleUpload} disabled={!uploadEnabled}>
          <Text size="sm">Upload</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
});

const MultiSelectDropdown = ({ options, currentSelection, updateSelection, selectAllText }) => {
  const toggle = (object) => {
    const alreadySelected = currentSelection.filter((it) => it.id === object.id);
    updateSelection(
      object.name === selectAllText ? [] : alreadySelected.length ? currentSelection.filter((it) => it.id !== object.id) : [...currentSelection, object]
    );
  };

  const generateArrayDescription = (array, emptyArrayDefault) => {
    let text = array.length ? "" : emptyArrayDefault;
    if (array.length) {
      array.forEach((object, index) => {
        text = text + `${object.name}${index < array.length - 1 ? ", " : ""}`;
      });
    }
    return text;
  };

  return (
    <Dropdown>
      <DropdownButton size="sm" disabled={false}>
        <DropdownButtonText>
          <ItemBody>
            <span>{generateArrayDescription(currentSelection, selectAllText)}</span>
          </ItemBody>
          <IconDownArrow className="btn-sm-svg" />
        </DropdownButtonText>
      </DropdownButton>
      <DropdownList>
        {options.map((option, index) => {
          return (
            <DropdownListItem
              onClick={() => {
                toggle(option);
              }}
            >
              <DropdownItem index={index}>
                <ItemBody>{option.name}</ItemBody>
                {currentSelection.find((it) => it.id === option.id) ? <IconCheck className="btn-sm-svg" /> : ""}
              </DropdownItem>
            </DropdownListItem>
          );
        })}
      </DropdownList>
    </Dropdown>
  );
};

const DropdownPanel = ({ title, options, selectedOption, onClick, nameKey = "name" }) => {
  return (
    <div className="pt-20">
      <Text size="sm" weight="medium" variant="secondary">
        {title}
      </Text>
      <div className="pt-8">
        <Dropdown>
          <DropdownButton size="sm" disabled={false}>
            <DropdownButtonText>
              <ItemBody>
                <span>{selectedOption ? selectedOption[nameKey] : "Please select"}</span>
              </ItemBody>
              <IconDownArrow className="btn-sm-svg" />
            </DropdownButtonText>
          </DropdownButton>
          <DropdownList>
            {options.map((option, index) => {
              return (
                <DropdownListItem
                  onClick={() => {
                    onClick(option);
                  }}
                  key={index}
                >
                  <DropdownItem index={index}>
                    <ItemBody>{option[nameKey]}</ItemBody>
                  </DropdownItem>
                </DropdownListItem>
              );
            })}
          </DropdownList>
        </Dropdown>
      </div>
    </div>
  );
};

export { ManualFileUploadModal };
