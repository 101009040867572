import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import "./animatedPanel.css";

const AnimatedPanel = observer(({ showButton = false, expandFrom = "0px", expandTo = "20%", open = false, children }) => {
  const [initialised, setInitialised] = useState(false);

  const panelElement = document.getElementById("sidePanel");
  const buttonElement = document.getElementById("sidePanelButton");
  const layoutElement = document.getElementsByClassName("baselayout-main")[0];

  //When the page loads, set the margin for baselayout
  if (!initialised && layoutElement) {
    layoutElement.style.marginRight = expandFrom;
    setInitialised(true);
  }

  //When the component unmounts, reset the margin for baselayout to ensure other pages aren't affected
  useEffect(
    () => () => {
      const baseLayout = document.getElementsByClassName("baselayout-main")[0];
      if (baseLayout) {
        baseLayout.style.marginRight = "0px";
      }
    },
    []
  );

  if (panelElement && layoutElement) {
    if (open) {
      panelElement.style.width = expandTo;
      layoutElement.style.marginRight = expandTo;
      if (buttonElement) {
        buttonElement.style.right = "19.5%";
      }
    } else {
      panelElement.style.width = expandFrom;
      layoutElement.style.marginRight = expandFrom;
      if (buttonElement) {
        buttonElement.style.right = "1.5%";
      }
    }
  }

  return <div id="sidePanel">{children}</div>;
});

export { AnimatedPanel };
