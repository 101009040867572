import { useState } from "react";
import { IconDownArrow, IconUpArrow, R, C10, C2 } from "@fundrecs/ui-library";

const AccordianPanel = ({ title, openDefault = false, children }) => {
  const [open, setOpen] = useState(openDefault);
  return (
    <div style={{ marginTop: "16px", borderTop: "1px solid #F1F5F9" }}>
      <R props="mt-16">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <R>
            <C10>{title}</C10>
            <C2>{open ? <IconUpArrow className="btn-md-svg" /> : <IconDownArrow className="btn-lg-svg" />}</C2>
          </R>
        </span>
      </R>
      <R props="mb-16">
        <div hidden={!open}>{children}</div>
      </R>
    </div>
  );
};

export { AccordianPanel };
