import { fusionRequest } from "../fusionRequest";

const carryForward = {
  getCarryActions: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/carryAction?recTypeId=${recTypeId}`),
  createCarryForwardAutoGroups: (teamId, body) => fusionRequest.post(`team/${teamId}/carryGroup/auto`, body),
  fetchCarryForwardRules: (teamId, recTypeId) => fusionRequest.get(`team/${teamId}/carryGroup?recTypeId=${recTypeId}`),
  deleteRowGroupCarryForwardRule: (teamId, id, version) => fusionRequest.delete(`team/${teamId}/carryGroup/auto/${id}?version=${version}`),
  enableRowGroupCarryForwardRule: (teamId, body) => fusionRequest.put(`team/${teamId}/carryGroup/auto`, body),
  createCarryForwardManualActions: (teamId, body) => fusionRequest.post(`team/${teamId}/carryGroup/manual`, body),
  deleteManualActionCarryForwardRule: (teamId, id, version) => fusionRequest.delete(`team/${teamId}/carryGroup/manual/${id}?version=${version}`),
  enableManualActionCarryForwardRule: (teamId, body) => fusionRequest.put(`team/${teamId}/carryGroup/manual`, body),
};

export { carryForward };
